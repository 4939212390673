import React from "react"
import "./PopupMessage.css"

const PopupMessage = ({ message, onClose }) => {
  return (
    <div className="popup-message">
      <div className="popup-message-content">
        <span role="img" aria-label="icon">
          
        </span>
        <p>{message}</p>
        <button onClick={onClose}>&times;</button>
      </div>
    </div>
  )
}

export default PopupMessage
