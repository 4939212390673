import React from "react";

const EmptyProjects = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100vh - 150px)", // Adjust this value based on your navbar and button height
      textAlign: "center",
      padding: "20px",
      boxSizing: "border-box",
    }}
  >
    <svg className="empty-icon" viewBox="0 0 100 100" width="100" height="100">
      <circle
        cx="50"
        cy="50"
        r="45"
        stroke="rgb(146, 98, 201)"
        strokeWidth="8"
        fill="none"
      />
      <line
        x1="20"
        y1="50"
        x2="80"
        y2="50"
        stroke="rgb(146, 98, 201)"
        strokeWidth="8"
      />
    </svg>
    <p
      style={{
        fontSize: "24px",
        fontWeight: "bold",
        color: "#333",
        marginBottom: "10px",
      }}
    >
      Oops! No projects found.
    </p>
    <p
      style={{
        fontSize: "18px",
        color: "#666",
      }}
    >
      Click on "Create Project" to get started!
    </p>
  </div>
);

export default EmptyProjects;
