import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import Home from "./components/Home";
// import Header from "./common/Header"
import Menu from "./components/Menu";
import Register from "./components/Register";
import Login from "./components/Login";
import Profile from "./components/Profile";
import Users from "./components/Users";
import ForgotPassword from "./components/ForgotPassword";
import Modules from "./components/Modules";
import CloudProjects from "./components/CloudProjects";
import Welcome from "./components/Welcome";
import ViewProject from "./components/ViewProject";
import PiiDetectorProjects from "./components/PiiDetectorProjects";
import ViewPiiProjects from "./components/ViewPiiProjects";
import EmailTemplate from "./components/EmailTemplate";
import GenericModals from "./components/GenericModals";
import SentimentAnalysisProjects from "./components/SentimentAnalysisProject";
import ViewSentimentAnalysis from "./components/ViewSentimentAnalysis";
import TwoLevelThematicProjects from "./components/TwoLevelThematicProjects";
import ViewTwoLevelProjects from "./components/ViewTwoLevelProjects";
import ThreeLevelThematicProjects from "./components/ThreeLevelThematicProjects";
import ViewThreeLevelProjects from "./components/ViewThreeLevelProjects";
import PiiGenAi from "./components/PiiGenAiProjects";
import ViewPiiGenAiProjects from "./components/ViewPiiGenAiProjects";
import GibberishDetectorProjects from "./components/GibberishDetectorProjects";
import ViewGibberishDetectorProjects from "./components/ViewGibberishDetectorProjects";
import AzureCSATProjects from "./components/AzureCSATProjects";
import ViewAzureCSATProjects from "./components/ViewAzureCSATProjects";
import OneLevelThematicProjects from "./components/OneLevelThematicAnalysis";
import ViewOneLevelProjects from "./components/ViewOneLevelProjects";
import TextTranslatorProjects from "./components/TextTranslatorProjects";
import ViewTextTranslatorProjects from "./components/ViewTextTranslatorProjects";

export default function App() {
  return (
    <>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/users" element={<Users />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/modules" element={<Modules />} />
        <Route path="/cloudnative-projects" element={<CloudProjects />} />
        <Route path="/main" element={<Welcome />} />
        <Route path="/task-details/:taskId" element={<ViewProject />} />
        <Route path="/piidetector-projects" element={<PiiDetectorProjects />} />
        <Route path="/pii-task-details/:taskId" element={<ViewPiiProjects />} />
        <Route path="/email-preview" element={<EmailTemplate />} />
        <Route path="/generic-modals" element={<GenericModals />} />
        <Route path="/azure-csat-projects" element={<AzureCSATProjects />} />
        <Route
          path="/text-translation-tasks"
          element={<TextTranslatorProjects />}
        />
        <Route
          path="/azure-csat-task-details/:taskId"
          element={<ViewAzureCSATProjects />}
        />
        <Route
          path="/sentiment-analysis-tasks"
          element={<SentimentAnalysisProjects />}
        />
        <Route
          path="/sentiment-task-details/:taskId"
          element={<ViewSentimentAnalysis />}
        />
        <Route
          path="/one-level-thematic-tasks"
          element={<OneLevelThematicProjects />}
        />
        <Route
          path="/one-level-thematic-analysis-task-details/:taskId"
          element={<ViewOneLevelProjects />}
        />
        <Route
          path="/two-level-thematic-tasks"
          element={<TwoLevelThematicProjects />}
        />
        <Route
          path="/two-level-thematic-analysis-task-details/:taskId"
          element={<ViewTwoLevelProjects />}
        />
        <Route
          path="/three-level-thematic-tasks"
          element={<ThreeLevelThematicProjects />}
        />
        <Route
          path="/three-level-thematic-analysis-task-details/:taskId"
          element={<ViewThreeLevelProjects />}
        />
        <Route path="/pii-gen-ai-tasks" element={<PiiGenAi />} />
        <Route
          path="/pii-genai-task-details/:taskId"
          element={<ViewPiiGenAiProjects />}
        />
        <Route
          path="/gibberish-detector-tasks"
          element={<GibberishDetectorProjects />}
        />
        <Route
          path="/gibberish-detector-task-details/:taskId"
          element={<ViewGibberishDetectorProjects />}
        />
        <Route
          path="/text-translation-task-details/:taskId"
          element={<ViewTextTranslatorProjects />}
        />
      </Routes>
    </>
  );
}
