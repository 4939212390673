import React, { useRef, useState, useEffect } from "react";
import { useLocation ,useParams} from "react-router-dom";
import * as XLSX from "xlsx";
import Menu from "./Menu";
import {
  uploadPiiFile,
  selectColumnFromPii,
  selectSheetFromPii,
  getUploadedPiiData,
  runPiiAnalysis,
  getPiiTaskStatus
} from "./apiService";
import { piiwebSocketConnection ,downloadPiiOutput} from "./apiService2";
import PopupMessage from "./PopupMessage";
import PiiOutputab from './PiiOutputab'; 
import "./ViewProject.css";
import "./ViewPiiProjects.css";

function ViewPiiProject() {
  const location = useLocation();
  const { taskId } = useParams();
  const [project, setProject] = useState(null);
  // const [taskId, setTaskId] = useState(null);


  const [uploading, setUploading] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [selectedSheet, setSelectedSheet] = useState("");
  const [availableSheets, setAvailableSheets] = useState([]);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);
  const fileInputRef = useRef(null);
  const [activeTab, setActiveTab] = useState('input');
  const [isProcessing, setIsProcessing] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);
  const [isAnalysisStarted, setIsAnalysisStarted] = useState(false);
  const [wsError, setWsError] = useState(null);
  const socketRef = useRef(null);

  const [visibleColumnStart, setVisibleColumnStart] = useState(0);
  const columnsToShow = 5;

  const totalColumns = uploadedData ? Object.keys(uploadedData[0]).length : 0;
  const totalPages = Math.ceil(totalColumns / columnsToShow);
  const currentPage = Math.floor(visibleColumnStart / columnsToShow) + 1;

  useEffect(() => {
    if (taskId) {
      console.log("Setting up PII project with task ID:", taskId);
      fetchProjectData(taskId);
      fetchTaskStatus(taskId);
    }
  }, [taskId]);

  useEffect(() => {
    if (taskId && !isTaskCompleted) {
      socketRef.current = piiwebSocketConnection(
        taskId,
        handleWebSocketMessage,
        handleWebSocketError
      );

      // return () => {
      //   if (socketRef.current) {
      //     socketRef.current.close();
      //   }
      // };
    }
  }, [taskId, isTaskCompleted]);



  const fetchProjectData = async (id) => {
    try {
      const response = await getUploadedPiiData(id);
      if (response && response.task_details) {
        const { task_details, pii_file_blob } = response;
        setProject(task_details);
        setProjectDetails(task_details);
        setSelectedSheet(task_details.pii_selected_sheet || "");
        setSelectedColumn(task_details.pii_selected_column || "");

        if (pii_file_blob) {
          const piiData = await processBase64Excel(pii_file_blob);
          if (piiData) {
            setAvailableSheets(piiData.sheets);
            setUploadedData(piiData.data);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
      setPopupMessage("Failed to fetch project data.");
      setShowPopup(true);
    }
  };

  const fetchTaskStatus = async (id) => {
    try {
      const response = await getPiiTaskStatus(id);
      setIsAnalysisStarted(response.is_analysis_started);
      setIsProcessing(response.is_analysis_started && !response.task_completed);
      setIsTaskCompleted(response.task_completed);
      setCompletionPercentage(response.task_completion_percentage || 0);
    } catch (error) {
      console.error("Error fetching task status:", error);
      setPopupMessage("Failed to fetch task status. Please try again.");
      setShowPopup(true);
    }
  };
  const handleWebSocketMessage = (data) => {
    console.log('Received WebSocket message:', data);
    if (data.task_completion_percentage !== undefined) {
      setCompletionPercentage(data.task_completion_percentage);
    }
    if (data.task_completed !== undefined) {
      setIsTaskCompleted(data.task_completed);
      setIsProcessing(false);
    }
  };

  const handleWebSocketError = (error) => {
    console.error('WebSocket error:', error);
    setWsError('Error connecting to server. Please try again.');
    setIsProcessing(false);
  };

  const processBase64Excel = async (base64String) => {
    try {
      const binaryString = atob(base64String);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const arrayBuffer = bytes.buffer;

      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const sheets = workbook.SheetNames;
      const firstSheet = sheets[0];
      const data = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);

      return { sheets, data };
    } catch (error) {
      console.error("Error processing base64 Excel data:", error);
      throw error;
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
  
    if (!file || !taskId) {
      return;
    }
  
    setUploading(true);
    setUploadedFile(file);
  
    try {
      const response = await uploadPiiFile(taskId, file);
  
      if (response.message) {
        setPopupMessage(response.message);
        setShowPopup(true);
  
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = event.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetNames = workbook.SheetNames;
  
          setAvailableSheets(sheetNames);
          setSelectedSheet(sheetNames[0]);
  
          const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNames[0]]);
          setUploadedData(worksheet);
          setUploading(false);
        };
        reader.readAsBinaryString(file);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploading(false);
      setPopupMessage("Failed to upload the file.");
      setShowPopup(true);
    }
  };
  const handleSheetSelect = async (e) => {
    const selectedSheetName = e.target.value;
    setSelectedSheet(selectedSheetName);
  
    try {
      await selectSheetFromPii(taskId, selectedSheetName);
      setPopupMessage("Sheet selected successfully.");
      setShowPopup(true);
  
      if (uploadedFile) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = event.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[selectedSheetName]);
  
          if (worksheet) {
            setUploadedData(worksheet);
          } else {
            console.error("Failed to parse worksheet.");
            setPopupMessage("Failed to load sheet data.");
            setShowPopup(true);
          }
        };
        reader.readAsBinaryString(uploadedFile);
      }
    } catch (error) {
      console.error("Error selecting sheet:", error);
      setPopupMessage("Failed to select sheet. Please try again.");
      setShowPopup(true);
    }
  };
  
  const handleColumnSelect = async (e) => {
    const columnName = e.target.value;
    setSelectedColumn(columnName);
  
    try {
      await selectColumnFromPii(taskId, columnName);
      setPopupMessage("Column selected successfully.");
      setShowPopup(true);
    } catch (error) {
      console.error("Error selecting column:", error);
      setPopupMessage("Failed to select column. Please try again.");
      setShowPopup(true);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handlePageChange = pageNumber => {
    const newStartIndex = (pageNumber - 1) * columnsToShow;
    setVisibleColumnStart(newStartIndex);
  };
  const handleRunClick = async () => {
    if (!taskId) {
      setPopupMessage("Task ID is missing. Please try reloading the page.");
      setShowPopup(true);
      return;
    }

    setIsProcessing(true);
    setWsError(null);
  
    try {
      const response = await runPiiAnalysis(taskId);
      if (response.message === 'File started processing successfully.') {
        socketRef.current = piiwebSocketConnection(
          taskId,
          handleWebSocketMessage,
          handleWebSocketError
        );
        setIsAnalysisStarted(true);
        await fetchTaskStatus(taskId);
      }
      setPopupMessage(response.message);
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);

    } catch (error) {
      console.error("Failed to run analysis:", error);
      setPopupMessage(error.response?.data?.error || "Failed to run analysis. Please try again.");
      setShowPopup(true);
      setIsProcessing(false);
    }
  };

  const handleDownloadClick = async () => {
    if (isTaskCompleted && taskId) {
      try {
        await downloadPiiOutput(taskId);
        setPopupMessage("File downloaded successfully.");
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 5000);
      } catch (error) {
        console.error("Error downloading file:", error);
        setPopupMessage("Failed to download the file. Please try again.");
        setShowPopup(true);
      }
    }
  };
  return (
    <div className="cloud-native-projects">
    <Menu />
    <h2>{project?.task_name || "PII Detection Task"}</h2>
    <div className="mt-3">
      <p>{project?.task_description || "No description available."}</p>
    </div>
    <div className="progress-and-button-container">
      <div className="progress-container">
        <div
          className="progress-bar"
          style={{ width: `${completionPercentage}%` }}
        ></div>
      </div>
      <div className="run-button-container">
        <button
          className="btn btn-success"
          onClick={isTaskCompleted ? handleDownloadClick : handleRunClick}
          disabled={!selectedColumn || isProcessing}
        >
          {isProcessing ? "Processing..." : isTaskCompleted ? "Download" : "Run"}
        </button>
      </div>
    </div>
    <div className="progress-info" style={{ marginBottom: '20px' }}>
      <div>Progress: {completionPercentage}%</div>
      {wsError && <div className="error-message">{wsError}</div>}
    </div>
      <div className="nav-tabs-container">
        <ul className="nav nav-tabs mb-3">
          <li className="nav-item">
            <button className={`nav-link ${activeTab === 'input' ? 'active' : ''}`} onClick={() => setActiveTab('input')}>Input Data</button>
          </li>
          <li className="nav-item">
          <button className={`nav-link ${activeTab === 'cost' ? 'active' : ''}`} onClick={() => setActiveTab('cost')}>Cost Estimation</button>
        </li>
          <li className="nav-item">
            <button className={`nav-link ${activeTab === 'output' ? 'active' : ''}`} onClick={() => setActiveTab('output')}>Output File</button>
          </li>
        </ul>
      </div>
      {activeTab === 'input' && (
        <div className="view-project-container">
          <div className="left-section">
            <div className="inline-flex-container">
              <button 
                className={`btn-excel-upload ${isAnalysisStarted ? 'analysis-started' : ''}`}
                onClick={handleUploadClick} 
                disabled={uploading || isAnalysisStarted}
              >
                {uploading ? "Processing..." : "Upload File"}
              </button>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h6>PII File</h6>
              <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} ref={fileInputRef} style={{ display: "none" }} />
            </div>
            {availableSheets.length > 0 && (
              <div className="mt-3">
                <h6>Select a Sheet:</h6>
                <select className="form-select" value={selectedSheet} onChange={handleSheetSelect}>
                  {availableSheets.map((sheetName, index) => (
                    <option key={index} value={sheetName}>
                      {sheetName}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {uploadedData && (
              <div className="mt-3">
                <h6>Select a Column:</h6>
                <select className="form-select" value={selectedColumn} onChange={handleColumnSelect}>
                  <option value="" disabled>
                    Select a column
                  </option>
                  {Object.keys(uploadedData[0]).map((columnName, index) => (
                    <option key={index} value={columnName}>
                      {columnName}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {uploadedData && (
              <div className="mt-3">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {Object.keys(uploadedData[0])
                        .slice(visibleColumnStart, visibleColumnStart + columnsToShow)
                        .map((columnName, index) => (
                          <th key={index}>{columnName}</th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {uploadedData.slice(0, 3).map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.values(row)
                          .slice(visibleColumnStart, visibleColumnStart + columnsToShow)
                          .map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell}</td>
                          ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    Showing {columnsToShow} out of {totalColumns} columns
                  </div>
                  <div className="pagination">
                    <button className={`btn btn-secondary ${currentPage === 1 ? "disabled" : ""}`} onClick={() => handlePageChange(currentPage - 1)}>
                      Previous
                    </button>
                    {[...Array(totalPages)].map((_, pageIndex) => (
                      <button key={pageIndex} className={`btn btn-secondary ${currentPage === pageIndex + 1 ? "active" : ""}`} onClick={() => handlePageChange(pageIndex + 1)}>
                        {pageIndex + 1}
                      </button>
                    ))}
                    <button className={`btn btn-secondary ${currentPage === totalPages ? "disabled" : ""}`} onClick={() =>
                    handlePageChange(currentPage + 1)}>
                  Next
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )}
   {activeTab === 'output' && (
        <PiiOutputab 
          isTaskCompleted={isTaskCompleted}
          projectId={taskId}
          handleDownloadClick={handleDownloadClick}
        />
      )}
    {showPopup && <PopupMessage message={popupMessage} />}
  </div>
);
}

export default ViewPiiProject;