import React from "react";
import "./CostEstimationPopup.css";

function CostEstimationPopup({
  costEstimation,
  onClose,
  onConfirm,
  agreementChecked,
  setAgreementChecked,
}) {
  // Format number to fixed decimal places and add commas for thousands
  const formatNumber = (num, decimalPlaces = 2) => {
    return num.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="cost-estimation-popup">
      <div className="cost-estimation-content">
        <h4>Cost Estimation Report</h4>
        <div className="estimation-details">
          <h6>Verbatim Analysis</h6>
          <ul>
            <li>Total Rows: {formatNumber(costEstimation.row_length, 0)}</li>
            <li>
              Total Words:{" "}
              {formatNumber(costEstimation.total_verbatim_words, 0)}
            </li>
            <li>
              Total Tokens:{" "}
              {formatNumber(costEstimation.total_verbatim_tokens, 1)}
            </li>
          </ul>

          <h6>Token Usage</h6>
          <ul>
            <li>
              Prompt Words per Verbatim:{" "}
              {formatNumber(costEstimation.prompt_words_per_verbatim, 0)}
            </li>
            <li>
              Output Words per Verbatim:{" "}
              {formatNumber(costEstimation.output_words_per_verbatim, 0)}
            </li>
            <li>
              Total Prompt Tokens:{" "}
              {formatNumber(costEstimation.total_prompt_tokens, 1)}
            </li>
            <li>
              Total Input Tokens:{" "}
              {formatNumber(costEstimation.total_input_tokens, 1)}
            </li>
            <li>
              Total Output Tokens:{" "}
              {formatNumber(costEstimation.total_output_tokens, 1)}
            </li>
          </ul>

          <h6>Cost Breakdown</h6>
          <ul>
            <li>
              Input Token Cost Rate: $
              {formatNumber(costEstimation.input_token_cost_rate, 2)} per 1000
              tokens
            </li>
            <li>
              Output Token Cost Rate: $
              {formatNumber(costEstimation.output_token_cost_rate, 2)} per 1000
              tokens
            </li>
            <li>
              Input Token Cost: $
              {formatNumber(costEstimation.input_token_cost, 6)}
            </li>
            <li>
              Output Token Cost: $
              {formatNumber(costEstimation.output_token_cost, 6)}
            </li>
            <li>
              <strong>
                Total Estimated Cost: $
                {formatNumber(costEstimation.total_cost, 6)}
              </strong>
            </li>
          </ul>
        </div>

        <div className="agreement-checkbox">
          <input
            type="checkbox"
            id="agreementCheckbox"
            checked={agreementChecked}
            onChange={(e) => setAgreementChecked(e.target.checked)}
          />
          <label htmlFor="agreementCheckbox">
            I agree to the estimated cost of $
            {formatNumber(costEstimation.total_cost, 6)} (INR:{" "}
            {formatNumber(costEstimation.total_cost_inr, 2)})
          </label>
        </div>

        <div className="popup-buttons">
          <button onClick={onClose} className="popup-cancel-button">
            Cancel
          </button>
          <button
            onClick={onConfirm}
            disabled={!agreementChecked}
            className="popup-run-button"
          >
            Run
          </button>
        </div>
      </div>
    </div>
  );
}

export default CostEstimationPopup;
