import React from "react"
import "./BackGround.css"

function BackGround() {
  return (
    <>
      <div className="background"></div>
    </>
  )
}

export default BackGround
