import React from "react"
import { useLocation } from "react-router-dom"
import "./Welcome.css"
import Menu from "./Menu"

function Welcome() {
  const location = useLocation()
  const userName = location.state?.userName

  return (
    <>
      <Menu />
      <div className="welcome-background">
        <div className="welcome-message text">
          Hello {userName},<p>Welcome to KakarotGenics !</p>
        </div>
      </div>
    </>
  )
}

export default Welcome
