import React from "react";
import "@fortawesome/fontawesome-free/css/all.css";
import { NavLink } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    // Footer
    <footer className="text-center text-lg-start bg-body-tertiary text-muted">
      {/* Section: Social media */}
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div>
        <div className="social-icons">
          <NavLink
            to="https://www.linkedin.com/company/c5i-ai/"
            className="me-4 social-icon"
          >
            <i className="fab fa-linkedin-in" />
          </NavLink>
          <NavLink
            to="https://www.youtube.com/@c5i-ai"
            className="me-4 social-icon"
          >
            <i className="fab fa-youtube" />
          </NavLink>
          <NavLink to="#" className="me-4 social-icon">
            <i className="fab fa-twitter" />
          </NavLink>
          <NavLink to="#" className="me-4 social-icon">
            <i className="fab fa-facebook-f" />
          </NavLink>
        </div>
      </section>
      {/* Section: Social media */}
      {/* Section: Links  */}
      <section>
        <div className="container text-center text-md-start mt-5">
          {/* Grid row */}
          <div className="row mt-3">
            {/* Grid column */}
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              {/* Content */}
              <h6 className="text-uppercase fw-bold mb-4 c5i-text">
                <i className="fas fa-gem me-3 text-course5i" />
                C5I
              </h6>
              <p>
                C5i announces the acquisition of Analytic Edge, strengthens its
                marketing analytics and Al solutions, enhancing business impact
                for its clients.
              </p>
            </div>
            {/* Grid column */}
            {/* Grid column */}
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              {/* Links */}
              <h6 className="text-uppercase fw-bold mb-4 c5i-products">
                Products
              </h6>
              <p>
                <a href="#!" className="text-reset">
                  Cloud Native
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  CSAT
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  PII Detector
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Scraping
                </a>
              </p>
            </div>
            {/* Grid column */}
            {/* Grid column */}
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 c5i-links">
                Useful links
              </h6>
              <p>
                <a href="#!" className="text-reset">
                  Pricing
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Settings
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Terms and conditions
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Help
                </a>
              </p>
            </div>
            {/* Grid column */}
            {/* Grid column */}
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              {/* Links */}
              <h6 className="text-uppercase fw-bold mb-4 contact-details">
                Contact
              </h6>
              <p>
                <i className="fas fa-home me-3 contact-home" />
                Banglore
              </p>
              <p>
                <i className="fas fa-envelope me-3 contact-email" />
                TXTSense@c5i.ai
              </p>
              <p>
                <i className="fas fa-phone me-3 contact-phone" /> + 01 234 567
                88
              </p>
              <p>
                <i className="fas fa-print me-3 contact-telephone" /> + 01 234
                567 89
              </p>
            </div>
            {/* Grid column */}
          </div>
          {/* Grid row */}
        </div>
      </section>
      {/* Section: Links  */}
      {/* Copyright */}
      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © KakarotGenics 2024
      </div>
      {/* Copyright */}
    </footer>
  );
}

export default Footer;
