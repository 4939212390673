import React from "react";
import "./ConfirmationModal.css"; // Create this CSS file for styling

const ConfirmationModal = ({ show, onConfirm, onCancel, message }) => {
  if (!show) return null;

  return (
    <div className="confirmation-modal show" role="dialog">
      <div className="confirmation-modal-dialog">
        <div className="confirmation-modal-content">
          <div className="confirmation-modal-header">
            <h5 className="confirmation-modal-title">Delete User</h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={onCancel}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="confirmation-modal-body">
            <p>Are you sure you want to delete these Records? </p>
            <small>This action cannot be undone.</small>
          </div>
          <div className="confirmation-modal-footer">
            <button type="button" className="btn-cancel" onClick={onCancel}>
              Cancel
            </button>
            <button type="button" className="btn-confirm" onClick={onConfirm}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
