import React, { useRef, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import Menu from "./Menu";
import {
  uploadVerbatimFile,
  selectSheetFromVerbatim,
  selectColumnFromVerbatim,
  uploadcodeframeFile,
  selectSheetFromCodeframe,
  getAzureCsatTasksDetails,
  runAnalysis,
  //   getCostEstimation,
  //   fetctCostEstimationReport,
  getTaskStatus,
} from "./apiService3";
import { csatwebSocketConnection, downloadCsatOutput } from "./apiService2";
import CostEstimationPopup from "./CostEstimationPopup"; // Adjust the path as needed
import CostEstimationReport from "./CostEstimationReport";
import OutputFileTab from "./OutputFileTab";
import PopupMessage from "./PopupMessage";
import "./ViewProject.css";
import axios from "axios";

function ViewAzureCSATProjects() {
  const { taskId } = useParams();
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [uploading, setUploading] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [selectedSheet, setSelectedSheet] = useState("");
  const [availableSheets, setAvailableSheets] = useState([]);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);
  const [projectDetails, setProjectDetails] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);

  const [showCostEstimation, setShowCostEstimation] = useState(false);
  const [costEstimation, setCostEstimation] = useState(null);
  const [agreementChecked, setAgreementChecked] = useState(false);

  const [activeTab, setActiveTab] = useState("input");
  const [costEstimationReport, setCostEstimationReport] = useState(null);
  const [costEstimationError, setCostEstimationError] = useState(null);
  const [isCostEstimationLoading, setIsCostEstimationLoading] = useState(false);
  const [isAnalysisStarted, setIsAnalysisStarted] = useState(false);
  const [selectedCodeColumn, setSelectedCodeColumn] = useState("");
  const [selectedWordsColumn, setSelectedWordsColumn] = useState("");

  const [visibleColumnStart, setVisibleColumnStart] = useState(0);
  const columnsToShow = 5;

  const totalColumns = uploadedData ? Object.keys(uploadedData[0]).length : 0;
  const totalPages = Math.ceil(totalColumns / columnsToShow);
  const currentPage = Math.floor(visibleColumnStart / columnsToShow) + 1;

  const [wsError, setWsError] = useState(null);
  const socketRef = useRef(null);

  useEffect(() => {
    // fetchProjectData();
    fetchTaskStatus();

    console.log(taskId);

    socketRef.current = csatwebSocketConnection(
      taskId,
      handleWebSocketMessage,
      handleWebSocketError
    );

    // ... (keep existing cleanup logic)
  }, [taskId]);

  useEffect(() => {
    console.log("useEffect triggered with taskId:", taskId);

    if (!taskId || taskId === "undefined") {
      console.log("Invalid taskId detected");
      console.log("Fetching project data for taskId:", taskId);
      setError("Invalid task ID");
      setIsLoading(false);
      return;
    }

    fetchProjectData();
  }, [taskId]);

  const fetchTaskStatus = async () => {
    try {
      const response = await getTaskStatus(taskId);
      setIsAnalysisStarted(response.is_analysis_started);
    } catch (error) {
      console.error("Error fetching task status:", error);
      setPopupMessage("Failed to fetch task status.");
      setShowPopup(true);
    }
  };

  const handleWebSocketMessage = (data) => {
    setCompletionPercentage(data.task_completion_percentage);
    setIsTaskCompleted(data.task_completed);

    if (data.task_completed) {
      setIsProcessing(false);
    }
  };

  const handleWebSocketError = (error) => {
    console.error("WebSocket error:", error);
    setWsError("Error connecting to server. Please try again.");
    setIsProcessing(false);
  };
  //   const fetchCostEstimation = async () => {
  //     try {
  //       setIsCostEstimationLoading(true);
  //       setCostEstimationError(null);
  //       const response = await fetctCostEstimationReport(taskId);
  //       console.log("Raw response:", response); // Log the entire response
  //       if (
  //         response &&
  //         response.status === "success" &&
  //         response.report &&
  //         response.report.calculations
  //       ) {
  //         setCostEstimationReport(response.report);
  //       } else {
  //         console.error("Unexpected response structure:", response);
  //         throw new Error("Invalid response structure");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching cost estimation report:", error);
  //       setCostEstimationError(
  //         error.message ||
  //           "Failed to fetch cost estimation report. Please try again later."
  //       );
  //     } finally {
  //       setIsCostEstimationLoading(false);
  //     }
  //   };

  //   useEffect(() => {
  //     if (activeTab === "cost" && taskId) {
  //       fetchCostEstimation();
  //     }
  //   }, [activeTab, taskId]);

  // useEffect(() => {
  //   if (project.id) {
  //     fetchProjectData();
  //   }
  // }, [project.id]);

  const fetchProjectData = async () => {
    console.log("Fetching project data for taskId:", taskId);
    try {
      const response = await getAzureCsatTasksDetails(taskId);
      console.log("API Response:", response);
      if (response && response.task_details) {
        const { task_details, verbatim_file_blob, codeframe_file_blob } =
          response;

        setProject(task_details);
        // Update task details
        setProjectDetails(task_details);
        setSelectedSheet(task_details.verbatim_selected_sheet || "");
        setSelectedColumn(task_details.verbatim_analysis_column || "");
        setSelectedSheet1(task_details.codeframe_selected_sheet || "");

        // Process verbatim file if available
        if (verbatim_file_blob) {
          try {
            const verbatimData = await processBase64Excel(verbatim_file_blob);
            if (verbatimData) {
              console.log("Verbatim data processed:", verbatimData);
              setAvailableSheets(verbatimData.sheets);
              setUploadedData(verbatimData.data);
            }
          } catch (error) {
            console.error("Error processing verbatim file:", error);
            setPopupMessage("Error processing verbatim file.");
            setShowPopup(true);
          }
        } else {
          console.log("No verbatim file blob available");
        }

        // Process codeframe file if available
        if (codeframe_file_blob) {
          try {
            const codeframeData = await processBase64Excel(codeframe_file_blob);
            if (codeframeData) {
              console.log("Codeframe data processed:", codeframeData);
              setAvailableSheets1(codeframeData.sheets);
              setUploadedData1(codeframeData.data);
            }
          } catch (error) {
            console.error("Error processing codeframe file:", error);
            setPopupMessage("Error processing codeframe file.");
            setShowPopup(true);
          }
        } else {
          console.log("No codeframe file blob available");
        }

        // Set task completion status if available
        if (task_details.task_completed !== undefined) {
          setIsTaskCompleted(task_details.task_completed);
        }
        if (task_details.task_completion_percentage !== undefined) {
          setCompletionPercentage(task_details.task_completion_percentage);
        }
      } else {
        console.error("Unexpected response structure:", response);
        throw new Error("Unexpected response structure");
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
      setPopupMessage("Failed to fetch project data.");
      setShowPopup(true);
    } finally {
      setIsLoading(false);
    }
  };

  const processBase64Excel = async (base64String) => {
    try {
      const binaryString = atob(base64String);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const arrayBuffer = bytes.buffer;

      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const sheets = workbook.SheetNames;
      const firstSheet = sheets[0];
      const data = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);

      return { sheets, data };
    } catch (error) {
      console.error("Error processing base64 Excel data:", error);
      throw error;
    }
  };

  const handleDownloadClick = async () => {
    if (isTaskCompleted && taskId) {
      try {
        await downloadCsatOutput(taskId);
        setPopupMessage("File downloaded successfully.");
        setShowPopup(true);
      } catch (error) {
        console.error("Error downloading file:", error);
        setPopupMessage("Failed to download the file. Please try again.");
        setShowPopup(true);
      }
    }
  };
  // const checkProjectStatus = async () => {
  //   try {
  //     const data = await getProjectStatus(project.id);
  //     setCompletionPercentage(data.task_completion_percentage);
  //     setIsTaskCompleted(data.task_completed);

  //     if (!data.task_completed) {
  //       // If task is not completed, continue checking
  //       setTimeout(checkProjectStatus, 5000); // Poll every 5 seconds
  //     } else {
  //       setIsProcessing(false); // Processing is done
  //     }
  //   } catch (error) {
  //     console.error("Error fetching project status:", error);
  //   }
  // };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    setUploading(true);
    setUploadedFile(file);

    try {
      const response = await uploadVerbatimFile(taskId, file);

      if (response.message) {
        setPopupMessage(response.message);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);

        const reader = new FileReader();
        reader.onload = async (event) => {
          const data = event.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetNames = workbook.SheetNames;

          setAvailableSheets(sheetNames);

          // If there's only one sheet, automatically select it
          if (sheetNames.length === 1) {
            const worksheet = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetNames[0]]
            );

            // Set the data
            setUploadedData(worksheet);
            setSelectedSheet(sheetNames[0]);

            // Automatically call the API to select the sheet
            try {
              const sheetResponse = await selectSheetFromVerbatim(
                taskId,
                sheetNames[0]
              );
              if (sheetResponse.message) {
                setPopupMessage(sheetResponse.message);
                setShowPopup(true);
                setTimeout(() => setShowPopup(false), 2000);
              }
            } catch (error) {
              console.error("Error auto-selecting sheet:", error);
              setPopupMessage("Failed to auto-select sheet.");
              setShowPopup(true);
              setTimeout(() => setShowPopup(false), 2000);
            }
          } else {
            // If there are multiple sheets, set the default selected sheet
            setSelectedSheet(sheetNames[0]);
            const worksheet = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetNames[0]]
            );
            setUploadedData(worksheet);
          }
        };

        reader.onerror = () => {
          setUploading(false);
          setPopupMessage("Failed to read the file.");
          setShowPopup(true);
          setTimeout(() => setShowPopup(false), 2000);
        };

        reader.readAsBinaryString(file);
      }
    } catch (error) {
      setUploading(false);
      setPopupMessage("Failed to upload the verbatim file.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);
    } finally {
      setUploading(false);
      setFileUploaded(true);
    }
  };

  const handleSheetSelect = async (e) => {
    const selectedSheetName = e.target.value;
    setSelectedSheet(selectedSheetName);

    if (!selectedSheetName) {
      setPopupMessage("Please select a valid sheet.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);
      return;
    }

    try {
      const response = await selectSheetFromVerbatim(taskId, selectedSheetName);
      if (response.message) {
        setPopupMessage(response.message);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);

        if (uploadedFile) {
          // Ensure uploadedFile is set and valid
          const reader = new FileReader();
          reader.onload = (event) => {
            const data = event.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const worksheet = XLSX.utils.sheet_to_json(
              workbook.Sheets[selectedSheetName]
            );

            if (worksheet) {
              setUploadedData(worksheet);
            } else {
              console.error("Failed to parse worksheet.");
            }
          };
          reader.readAsBinaryString(uploadedFile);
        } else {
          console.error("Uploaded file is missing or invalid.");
          setPopupMessage("Uploaded file is missing or invalid.");
          setShowPopup(true);
          setTimeout(() => setShowPopup(false), 2000);
        }
      }
    } catch (error) {
      if (error.response) {
        console.error("Error from server:", error.response.data);
      } else {
        console.error("Failed to select sheet from verbatim:", error.message);
      }
      setPopupMessage("Failed to select sheet.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);
    }
  };

  const handleColumnSelect = async (e) => {
    const columnName = e.target.value;
    setSelectedColumn(columnName);
    try {
      const response = await selectColumnFromVerbatim(taskId, columnName);
      if (response.message) {
        setPopupMessage(response.message);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
      }
    } catch (error) {
      setPopupMessage("Failed to select column.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handlePageChange = (pageNumber) => {
    const newStartIndex = (pageNumber - 1) * columnsToShow;
    setVisibleColumnStart(newStartIndex);
  };
  const [uploading1, setUploading1] = useState(false);
  const [uploadedData1, setUploadedData1] = useState(null);
  const [selectedColumn1, setSelectedColumn1] = useState("");
  const [selectedSheet1, setSelectedSheet1] = useState("");
  const [availableSheets1, setAvailableSheets1] = useState([]);
  const [uploadedFile1, setUploadedFile1] = useState(null);
  const fileInputRef1 = useRef(null);

  const [codeframeValidationError, setCodeframeValidationError] = useState("");
  const [visibleColumnStart1, setVisibleColumnStart1] = useState(0);
  const columnsToShow1 = 5;

  const totalColumns1 = uploadedData1
    ? Object.keys(uploadedData1[0]).length
    : 0;
  const totalPages1 = Math.ceil(totalColumns1 / columnsToShow1);
  const currentPage1 = Math.floor(visibleColumnStart1 / columnsToShow1) + 1;

  const handleFileChange1 = async (e) => {
    const file1 = e.target.files[0];

    if (!file1) {
      return;
    }

    setUploading1(true);
    setUploadedFile1(file1);
    setCodeframeValidationError(""); // Clear any previous error

    // Reset all selections
    setSelectedSheet1("");
    setSelectedCodeColumn("");
    setSelectedWordsColumn("");
    setUploadedData1(null);

    try {
      const response1 = await uploadcodeframeFile(taskId, file1);

      if (response1.message) {
        setPopupMessage(response1.message);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);

        // Read the file data
        const fileData = await readExcelFile(file1);
        const workbook = XLSX.read(fileData, { type: "binary" });
        const sheetNames = workbook.SheetNames;

        setAvailableSheets1(sheetNames);
      }
    } catch (error) {
      console.error("Error processing file:", error);
      setPopupMessage("Failed to upload the file.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);
    } finally {
      setUploading1(false);
    }
  };

  // Helper function to read Excel file
  const readExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(new Error("Failed to read the file."));
      reader.readAsBinaryString(file);
    });
  };
  const validateSheetStructure = (worksheet) => {
    if (!worksheet || worksheet.length === 0) {
      return {
        isValid: false,
        error: "The selected sheet is empty.",
      };
    }

    // Get the headers (first row)
    const headers = Object.keys(worksheet[0]);

    // Check for Code column
    if (!headers.includes("Code")) {
      return {
        isValid: false,
        error:
          "This sheet does not have a 'Code' column. Please select a sheet with both 'Code' and 'Words' columns.",
      };
    }

    // Check for Words column
    if (!headers.includes("Words")) {
      return {
        isValid: false,
        error:
          "This sheet does not have a 'Words' column. Please select a sheet with both 'Code' and 'Words' columns.",
      };
    }

    // If both columns are missing
    if (!headers.includes("Code") && !headers.includes("Words")) {
      return {
        isValid: false,
        error:
          "This sheet does not have the required 'Code' and 'Words' columns. Please select a sheet with both columns.",
      };
    }

    // Validate data in each row
    for (let i = 0; i < worksheet.length; i++) {
      const row = worksheet[i];

      // Check if Words column has trailing commas
      if (
        row.Words &&
        typeof row.Words === "string" &&
        row.Words.trim().endsWith(",")
      ) {
        return {
          isValid: false,
          error: `Row ${
            i + 1
          } has an extra comma at the end of the Words column.`,
        };
      }

      // Check for empty required fields
      if (!row.Code || !row.Words) {
        return {
          isValid: false,
          error: `Row ${i + 1} has missing values in Code or Words columns.`,
        };
      }
    }

    return { isValid: true };
  };

  // Helper function to validate Excel structure
  const validateExcelStructure = (fileContents) => {
    const workbook = XLSX.read(fileContents, { type: "binary" });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    if (data.length === 0) {
      return {
        isValid: false,
        error: "The file is empty.",
        specificError: false,
      };
    }

    const headerRow = data[0];
    if (headerRow[0] !== "Code" || headerRow[1] !== "Words") {
      return {
        isValid: false,
        error: "The first row should contain 'Code' and 'Words' as headers.",
        specificError: true,
      };
    }

    for (let i = 1; i < data.length; i++) {
      const row = data[i];
      if (row[1] && row[1].endsWith(",")) {
        return {
          isValid: false,
          error: `Row ${
            i + 1
          } has an extra comma at the end of the Words column.`,
          specificError: true,
        };
      }
    }

    return { isValid: true };
  };

  const handleSheetSelect1 = async (e) => {
    const selectedSheetName1 = e.target.value;
    setCodeframeValidationError(""); // Clear any previous validation errors

    try {
      if (!uploadedFile1) {
        throw new Error("No file uploaded");
      }

      const fileData = await readExcelFile(uploadedFile1);
      const workbook = XLSX.read(fileData, { type: "binary" });
      const worksheet = XLSX.utils.sheet_to_json(
        workbook.Sheets[selectedSheetName1]
      );

      // Validate the sheet structure
      const validationResult = validateSheetStructure(worksheet);

      if (!validationResult.isValid) {
        setCodeframeValidationError(validationResult.error);
        setUploadedData1(null);
        setSelectedCodeColumn("");
        setSelectedWordsColumn("");
        return;
      }

      // If validation passes, update the state and call the API
      setSelectedSheet1(selectedSheetName1);

      const response1 = await selectSheetFromCodeframe(
        taskId,
        selectedSheetName1
      );

      if (response1.message) {
        setUploadedData1(worksheet);
        setPopupMessage("Sheet selected successfully");
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
      }
    } catch (error) {
      console.error("Error selecting sheet:", error);
      setPopupMessage("Failed to select sheet");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);
    }
  };
  //   const handleColumnSelect1 = async (e) => {
  //     const columnName1 = e.target.value;
  //     setSelectedColumn1(columnName1);

  //     try {
  //       // Call the API to select the column from the selected sheet
  //       const response1 = await selectColumnFromCodeframe(taskId, columnName1);

  //       if (response1.message) {
  //         setPopupMessage(response1.message);
  //         setShowPopup(true);
  //         setTimeout(() => setShowPopup(false), 2000);
  //       }
  //     } catch (error) {
  //       setPopupMessage("Failed to select the column.");
  //       setShowPopup(true);
  //       setTimeout(() => setShowPopup(false), 2000);
  //     }
  //   };

  const handleUploadClick1 = () => {
    fileInputRef1.current.click();
  };

  const handlePageChange1 = (pageNumber1) => {
    const newStartIndex1 = (pageNumber1 - 1) * columnsToShow1;
    setVisibleColumnStart1(newStartIndex1);
  };
  const handleCodeColumnSelect = async (e) => {
    const columnName = e.target.value;
    setSelectedCodeColumn(columnName);

    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.post(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/select-code-column-from-codeframe-azure-csat/",
        { task_id: taskId, code_column: columnName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.message) {
        setPopupMessage(response.data.message);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
      }
    } catch (error) {
      console.error("Failed to select code column:", error);
      setPopupMessage("Failed to select the code column.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);
    }
  };

  const handleWordsColumnSelect = async (e) => {
    const columnName = e.target.value;
    setSelectedWordsColumn(columnName);

    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.post(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/select-words-column-from-codeframe-azure-csat/",
        { task_id: taskId, words_column: columnName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.message) {
        setPopupMessage(response.data.message);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
      }
    } catch (error) {
      console.error("Failed to select words column:", error);
      setPopupMessage("Failed to select the words column.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);
    }
  };

  //   const handleRunClick = async () => {
  //     try {
  //       const estimationResponse = await getCostEstimation(taskId);
  //       setCostEstimation(estimationResponse.cost_estimation);
  //       setShowCostEstimation(true);
  //     } catch (error) {
  //       console.error("Failed to get cost estimation:", error);
  //       setPopupMessage("Failed to get cost estimation.");
  //       setShowPopup(true);
  //     }
  //   };

  const handleRunAnalysis = async () => {
    try {
      setIsProcessing(true);
      setWsError(null);

      const response = await runAnalysis(taskId);
      console.log(response);

      if (response.message === "File started processing successfully.") {
        socketRef.current = csatwebSocketConnection(
          taskId,
          handleWebSocketMessage,
          handleWebSocketError
        );
        setIsAnalysisStarted(true);
        await fetchTaskStatus();
      }

      if (response.message) {
        setPopupMessage(response.message);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
      }
    } catch (error) {
      console.error("Failed to run analysis:", error);
      setPopupMessage("Failed to run analysis.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
      setIsProcessing(false);
    }
  };

  const handleConfirmRun = async () => {
    if (!agreementChecked) {
      setPopupMessage(
        "Please agree to the cost estimation before running the analysis."
      );
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);
      return;
    }

    setIsProcessing(true);
    setWsError(null);
    setShowCostEstimation(false);

    try {
      const response = await runAnalysis(taskId);
      console.log(response);
      if (response.message === "File started processing successfully.") {
        socketRef.current = csatwebSocketConnection(
          taskId,
          handleWebSocketMessage,
          handleWebSocketError
        );
        setIsAnalysisStarted(true);
        await fetchTaskStatus();
      }
      if (response.message) {
        setPopupMessage(response.message);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
      }
    } catch (error) {
      console.error("Failed to run analysis:", error);
      setPopupMessage("Failed to run analysis.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
      setIsProcessing(false);
    }
  };

  return (
    <div className="cloud-native-projects">
      <Menu />
      <h2>{project?.project_name || project?.task_name || "Project"}</h2>

      <div className="mt-3">
        <p>
          {project?.project_description ||
            project?.task_description ||
            "No description available."}
        </p>
      </div>
      <div className="progress-and-button-container">
        <div className="progress-container">
          <div
            className="progress-bar"
            style={{ width: `${completionPercentage}%` }}
          ></div>
        </div>
        {/* <div className="progress-info">
    <div>Progress: {completionPercentage}%</div>
    {wsError && <div className="error-message">{wsError}</div>}
  </div> */}
        <div className="run-button-container">
          <button
            className="btn btn-success"
            onClick={handleRunAnalysis}
            disabled={!selectedColumn || isProcessing}
          >
            {isProcessing
              ? "Processing..."
              : isTaskCompleted
              ? "Download"
              : "Run"}
          </button>
        </div>
      </div>
      <div className="progress-info" style={{ marginBottom: "20px" }}>
        <div>Progress: {completionPercentage}%</div>
        {wsError && <div className="error-message">{wsError}</div>}
      </div>
      {/* {wsError && <div className="error-message">{wsError}</div>} */}
      <div className="nav-tabs-container">
        <ul className="nav nav-tabs mb-3">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "input" ? "active" : ""}`}
              onClick={() => setActiveTab("input")}
            >
              Input Data
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "cost" ? "active" : ""}`}
              onClick={() => setActiveTab("cost")}
            >
              Cost Estimation
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "output" ? "active" : ""}`}
              onClick={() => setActiveTab("output")}
            >
              Output File
            </button>
          </li>
          {/* <li className="nav-item">
          <button className={`nav-link ${activeTab === 'analysis' ? 'active' : ''}`} onClick={() => setActiveTab('analysis')}>Graphical Analysis</button>
        </li> */}
        </ul>
      </div>
      {activeTab === "input" && (
        <div className="view-project-container">
          {/* <h6>{project.project_name}</h6> */}
          <div className="left-section">
            <div className="inline-flex-container">
              <button
                className={`btn-excel-upload ${
                  isAnalysisStarted ? "analysis-started" : ""
                }`}
                onClick={handleUploadClick}
                disabled={uploading || isAnalysisStarted}
              >
                {uploading ? "Processing..." : "Upload File"}
              </button>
            </div>

            {/* <Menu /> */}
            <div className="d-flex justify-content-between align-items-center">
              {/* <h6>{project.project_name}</h6> */}
              <h6>Verbatim</h6>
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </div>
            {/* <div className="mt-3">
            <p>{project.project_description}</p>
          </div> */}

            {availableSheets.length > 0 && (
              <div className="mt-3">
                <h6>Select a Sheet:</h6>
                <select
                  className="form-select"
                  value={selectedSheet}
                  onChange={handleSheetSelect}
                >
                  {availableSheets.map((sheetName, index) => (
                    <option key={index} value={sheetName}>
                      {sheetName}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {uploadedData && (
              <div className="mt-3">
                <h6>Select a Column:</h6>
                <select
                  className="form-select"
                  value={selectedColumn}
                  onChange={handleColumnSelect}
                >
                  <option value="" disabled>
                    Select a column
                  </option>
                  {Object.keys(uploadedData[0]).map((columnName, index) => (
                    <option key={index} value={columnName}>
                      {columnName}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {uploadedData && (
              <div className="mt-3">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {Object.keys(uploadedData[0])
                        .slice(
                          visibleColumnStart,
                          visibleColumnStart + columnsToShow
                        )
                        .map((columnName, index) => (
                          <th key={index}>{columnName}</th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {uploadedData.slice(0, 3).map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.values(row)
                          .slice(
                            visibleColumnStart,
                            visibleColumnStart + columnsToShow
                          )
                          .map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell}</td>
                          ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    Showing {columnsToShow} out of {totalColumns} columns
                  </div>
                  <div className="pagination">
                    <button
                      className={`btn btn-secondary ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages)].map((_, pageIndex) => (
                      <button
                        key={pageIndex}
                        className={`btn btn-secondary ${
                          currentPage === pageIndex + 1 ? "active" : ""
                        }`}
                        onClick={() => handlePageChange(pageIndex + 1)}
                      >
                        {pageIndex + 1}
                      </button>
                    ))}
                    <button
                      className={`btn btn-secondary ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/* <div className="d-flex justify-content-end mt-1">
              <button
                className="btn btn-success"
                onClick={handleRunAnalysis}
                disabled={!selectedColumn || isProcessing}
              >
                {isProcessing ? "Processing..." : "Run"}
              </button>
            </div> */}

            {showPopup && <PopupMessage message={popupMessage} />}
          </div>
          <div className="right-section">
            {/* <div className="d-flex justify-content-end mt-1">
        <button
          className="btn btn-success"
          onClick={isTaskCompleted ? handleDownloadClick : handleRunClick}
          disabled={!selectedColumn || isProcessing}
        >
          {isProcessing ? "Processing..." : isTaskCompleted ? "Download" : "Run"}
        </button>
      </div> */}
            <div className="inline-flex-container">
              <button
                className={`btn-excel-upload-2 ${
                  isAnalysisStarted ? "analysis-started" : ""
                }`}
                onClick={handleUploadClick1}
                disabled={uploading1 || isAnalysisStarted}
              >
                {uploading1 ? "Processing..." : "Upload File"}
              </button>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <h6>CodeFrame</h6>
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileChange1}
                ref={fileInputRef1}
                style={{ display: "none" }}
              />
            </div>
            {/* {codeframeValidationError && (
              <div
                style={{
                  backgroundColor: "#FF3333",
                  color: "white",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  position: "relative",
                  margin: "10px 0",
                  fontSize: "14px",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  maxWidth: "100%",
                  width: "fit-content",
                }}
              >
                <span>{codeframeValidationError}</span>
                <button
                  onClick={() => setCodeframeValidationError("")}
                  style={{
                    background: "none",
                    border: "none",
                    color: "white",
                    fontSize: "18px",

                    cursor: "pointer",
                    marginLeft: "10px",
                    padding: "0 5px",
                  }}
                >
                  ×
                </button>
              </div>
            )} */}
            {/* <div className="mt-3">
            <p>Some Description</p>
          </div> */}
            {availableSheets1.length > 0 && (
              <div className="mt-3">
                <h6>Select a Sheet:</h6>
                <select
                  className="form-select"
                  value={selectedSheet1}
                  onChange={handleSheetSelect1}
                >
                  <option value="" disabled selected>
                    Select sheet
                  </option>
                  {availableSheets1.map((sheetName1, index1) => (
                    <option key={index1} value={sheetName1}>
                      {sheetName1}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* {uploadedData1 && (
            <div className="mt-3">
              <h6>Select a Column(Optional):</h6>
              <select className="form-select" value={selectedColumn1} onChange={handleColumnSelect1}>
                <option value="" disabled>
                  Select a column
                </option>
                {Object.keys(uploadedData1[0]).map((columnName1, index1) => (
                  <option key={index1} value={columnName1}>
                    {columnName1}
                  </option>
                ))}
              </select>
            </div>

          )} */}
            {codeframeValidationError && (
              <div
                style={{
                  backgroundColor: "#FF3333",
                  color: "white",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  position: "relative",
                  margin: "10px 0",
                  fontSize: "14px",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  maxWidth: "100%",
                  width: "fit-content",
                }}
              >
                <span>{codeframeValidationError}</span>
                <button
                  onClick={() => setCodeframeValidationError("")}
                  style={{
                    background: "none",
                    border: "none",
                    color: "white",
                    fontSize: "18px",

                    cursor: "pointer",
                    marginLeft: "10px",
                    padding: "0 5px",
                  }}
                >
                  ×
                </button>
              </div>
            )}

            {uploadedData1 && (
              <div className="mt-3">
                <h6>Select Code Column:</h6>
                <select
                  className="form-select"
                  value={selectedCodeColumn}
                  onChange={handleCodeColumnSelect}
                >
                  <option value="" disabled>
                    Select Code column
                  </option>
                  {Object.keys(uploadedData1[0]).map((columnName, index) => (
                    <option key={index} value={columnName}>
                      {columnName}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {uploadedData1 && (
              <div className="mt-3">
                <h6>Select Words Column:</h6>
                <select
                  className="form-select"
                  value={selectedWordsColumn}
                  onChange={handleWordsColumnSelect}
                >
                  <option value="" disabled>
                    Select Words column
                  </option>
                  {Object.keys(uploadedData1[0]).map((columnName, index) => (
                    <option key={index} value={columnName}>
                      {columnName}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {uploadedData1 && (
              <div className="mt-3">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {Object.keys(uploadedData1[0])
                        .slice(
                          visibleColumnStart1,
                          visibleColumnStart1 + columnsToShow1
                        )
                        .map((columnName1, index1) => (
                          <th key={index1}>{columnName1}</th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {uploadedData1.slice(0, 3).map((row1, rowIndex1) => (
                      <tr key={rowIndex1}>
                        {Object.values(row1)
                          .slice(
                            visibleColumnStart1,
                            visibleColumnStart1 + columnsToShow1
                          )
                          .map((cell1, cellIndex1) => (
                            <td key={cellIndex1}>{cell1}</td>
                          ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    Showing {columnsToShow1} out of {totalColumns1} columns
                  </div>
                  <div className="pagination">
                    <button
                      className={`btn btn-secondary ${
                        currentPage1 === 1 ? "disabled" : ""
                      }`}
                      onClick={() => handlePageChange1(currentPage1 - 1)}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages1)].map((_, pageIndex1) => (
                      <button
                        key={pageIndex1}
                        className={`btn btn-secondary ${
                          currentPage1 === pageIndex1 + 1 ? "active" : ""
                        }`}
                        onClick={() => handlePageChange1(pageIndex1 + 1)}
                      >
                        {pageIndex1 + 1}
                      </button>
                    ))}
                    <button
                      className={`btn btn-secondary ${
                        currentPage1 === totalPages1 ? "disabled" : ""
                      }`}
                      onClick={() => handlePageChange1(currentPage1 + 1)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}

            {showCostEstimation && costEstimation && (
              <CostEstimationPopup
                costEstimation={costEstimation}
                onClose={() => setShowCostEstimation(false)}
                // onConfirm={handleConfirmRun}
                agreementChecked={agreementChecked}
                setAgreementChecked={setAgreementChecked}
              />
            )}

            {showPopup && <PopupMessage message={popupMessage} />}
          </div>
        </div>
      )}

      {activeTab === "cost" && (
        <div className="cost-estimation-container">
          <CostEstimationReport
            report={costEstimationReport}
            error={costEstimationError}
            isLoading={isCostEstimationLoading}
          />
        </div>
      )}
      {activeTab === "output" && (
        <OutputFileTab
          isTaskCompleted={isTaskCompleted}
          projectId={taskId}
          handleDownloadClick={handleDownloadClick}
        />
      )}
      {activeTab === "analysis" && (
        <div className="graphical-analysis-container">
          <h3>Graphical Analysis</h3>
          {/* Add graphical analysis content here */}
          <p>
            Graphical analysis will be displayed here after the analysis is
            complete.
          </p>
        </div>
      )}
    </div>
  );
}

export default ViewAzureCSATProjects;
