import React from 'react';

const EmailTemplate = ({ recipientName, reportUrl }) => {
  const subject = "Analysis Update: Your Results are Ready";

  return (
    <div className="email-preview" style={{ fontFamily: 'Arial, sans-serif', maxWidth: '600px', margin: '0 auto', padding: '20px', border: '1px solid #ddd' }}>
      <h2 style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>Subject: {subject}</h2>
      
      <div style={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '5px' }}>
        <p>Dear {recipientName},</p>
        
        <p>We are pleased to inform you that the analysis you requested is now complete. You can access the detailed results by visiting the link below:</p>
        
        <p>
          <a href={reportUrl} target="_blank" rel="noopener noreferrer" style={{ color: '#1a0dab', textDecoration: 'none', fontWeight: 'bold' }}>
            {reportUrl} 
          </a>
        </p>
        
        <p>If you have any questions or require further assistance, please do not hesitate to reach out to us.</p>
        
        <div style={{ marginTop: '20px', fontStyle: 'italic', color: '#666666' }}>
          <p>
            Best regards,<br />
            C5i
          </p>
        </div>
      </div>
    </div>
  );
};

const EmailTemplateExample = () => {
  const recipientName = "John";
  const reportUrl = "https://example.com/your-report";

  return (
    <div>
      <EmailTemplate recipientName={recipientName} reportUrl={reportUrl} />
    </div>
  );
};

export default EmailTemplateExample;
export { EmailTemplate };