import React from "react";
import Header from "../common/Header";
import "./Home.css"; // Import the CSS file
import Footer from "../common/Footer";
import "@fortawesome/fontawesome-free/css/all.css";
import { NavLink } from "react-router-dom";

function Home() {
  return (
    <section>
      <Header />
      <div
        id="carouselExampleCaptions"
        className="carousel slide carousel-container"
        data-bs-ride="carousel"
        data-bs-interval="3000"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={0}
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          />
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={1}
            aria-label="Slide 2"
          />
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={2}
            aria-label="Slide 3"
          />
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="carousel-content-wrapper">
              <div className="carousel-text">
                <h5>Advanced TXTSense Platform</h5>
                <p>
                  Unlock the power of your unstructured data with our
                  cutting-edge AI-driven text analysis tools.
                </p>
              </div>
              <img
                src="https://www.ibm.com/content/dam/connectedassets-adobe-cms/worldwide-content/creative-assets/s-migr/ul/g/b6/98/cognos-planning-analytics-resources-leadspace.component.xl.ts=1721755386247.png/content/adobe-cms/us/en/products/cognos-analytics/support/_jcr_content/root/leadspace"
                className="d-block w-100"
                alt="Advanced Analytics Dashboard"
              />
            </div>
          </div>
          <div className="carousel-item">
            <div className="carousel-content-wrapper">
              <div className="carousel-text">
                <h5>Intelligent Insights</h5>
                <p>
                  Transform raw text into actionable insights with our machine
                  learning algorithms and natural language processing.
                </p>
              </div>
              <img
                src="https://www.ibm.com/content/dam/connectedassets-adobe-cms/worldwide-content/creative-assets/s-migr/ul/g/8a/f0/cognos-analytics-with-watson-leadspace-2.png/_jcr_content/renditions/cq5dam.medium.1584.1584.png"
                className="d-block w-100"
                alt="Intelligent Insights Visualization"
              />
            </div>
          </div>
          <div className="carousel-item">
            <div className="carousel-content-wrapper">
              <div className="carousel-text">
                <h5>Customizable Solutions</h5>
                <p>
                  Tailor our platform to your specific needs, from sentiment
                  analysis to topic modeling and beyond.
                </p>
              </div>
              <img
                src="https://www.ibm.com/content/dam/connectedassets-adobe-cms/worldwide-content/creative-assets/s-migr/ul/g/40/de/cognos-planning-analytics-marketing-leadspace-2.component.xl.ts=1713218197372.png/content/adobe-cms/us/en/products/cognos-analytics/marketing-analytics/_jcr_content/root/leadspace"
                className="d-block w-100"
                alt="Customizable Analytics Solutions"
              />
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <div className="container container-heading-section">
        <div className="row justify-content-center text-center">
          <div className="col-md-10 col-lg-8">
            <h1 className="h4">Our Services</h1>
            <p className="mt-4 mb-4">
              Discover our comprehensive suite of AI-powered text analytics
              solutions designed to transform your data into actionable
              insights. From cloud-native applications to advanced PII
              detection, we offer cutting-edge tools to meet your diverse
              analytical needs.
            </p>
          </div>
        </div>
      </div>

      <div className="home-cards-container">
        <div className="home-card">
          <div
            className="home-card-img"
            style={{
              backgroundImage:
                "url(https://www.growthaccelerationpartners.com/wp-content/uploads/2023/10/The-Fundamental-Difference-Between-Cloud-and-Cloud-Native-Development.jpg)",
            }}
          >
            <div className="home-overlay">
              <div className="home-overlay-content"></div>
            </div>
          </div>
          <div className="home-card-content">
            <a href="#!">
              <h2>Cloud Native</h2>
              <p>
                Leverage our cloud-native text analytics platform for scalable,
                efficient, and real-time data processing and insights
                generation.
              </p>
            </a>
          </div>
        </div>
        <div className="home-card">
          <div
            className="home-card-img"
            style={{
              backgroundImage:
                "url(https://news-cdn.moonbeam.co/Detect-PII-and-Sensitive-Information-In-Real-Time-App-Developer-Magazine_i0dzgjm4.jpg)",
            }}
          >
            <div className="home-overlay">
              <div className="home-overlay-content"></div>
            </div>
          </div>
          <div className="home-card-content">
            <a href="#!">
              <h2>PII Detector</h2>
              <p>
                Protect sensitive information with our advanced PII detection
                tool, ensuring compliance and data security across your text
                documents.
              </p>
            </a>
          </div>
        </div>
        <div className="home-card">
          <div
            className="home-card-img"
            style={{
              backgroundImage:
                "url(https://safe-connect.com/wp-content/uploads/2022/12/CSAT-Scan-1.png)",
            }}
          >
            <div className="home-overlay">
              <div className="home-overlay-content"></div>
            </div>
          </div>
          <div className="home-card-content">
            <a href="#!">
              <h2>CSAT </h2>
              <p>
                Gain deeper insights into customer satisfaction with our CSAT
                analysis tool, helping you improve products and services based
                on feedback.
              </p>
            </a>
          </div>
        </div>
        <div className="home-card">
          <div
            className="home-card-img"
            style={{
              backgroundImage:
                "url(https://media.bitcot.com/wp-content/uploads/2023/12/web-Scraping-tools.webp",
            }}
          >
            <div className="home-overlay">
              <div className="home-overlay-content"></div>
            </div>
          </div>
          <div className="home-card-content">
            <a href="#!">
              <h2>Web Scraping</h2>
              <p>
                Harness the power of web data with our intelligent scraping
                tools, enabling you to collect and analyze vast amounts of
                online information.
              </p>
            </a>
          </div>
        </div>
      </div>
      {/* <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-10 col-lg-8">
            <h1 className="h4">Our Team</h1>
            <p className="mt-4 mb-4">"Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..." "There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain..."</p>
          </div>
        </div>
      </div> */}
      <section className="kakarotgenics-journey">
        <div className="journey-content">
          <h3>Start your Journey with KakarotGenics today</h3>
          <p>
            Supercharge your analytics with AI. Discover patterns, predict
            trends, and drive innovation with KakarotGenics.
          </p>
          <NavLink className="signup-button" to="/register">
            Sign Up
          </NavLink>
        </div>
        <div className="journey-images">
          <img
            src="https://sitechecker.pro/wp-content/uploads/2023/12/what-is-mobile-analytics.png"
            alt="KakarotGenics Interface 1"
            className="interface-image"
          />
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7YXcRi4PQl9B1NL5zinGeOWUm1FRt4nFVbOgI9_nIM-mVwoZMWxYSAX_IeuL9_hbAaEY&usqp=CAU"
            alt="KakarotGenics Interface 2"
            className="interface-image"
          />
          <img
            src="https://www.smartlook.com/wp-content/uploads/2022/06/img-hookle_1.jpg"
            alt="KakarotGenics Interface 3"
            className="interface-image"
          />
        </div>
      </section>

      {/* <section id="sec-contact" className="home-sec-contact pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="next-steps-container">
                <h2>Get Connected</h2>
                <p>Have a question? Get in touch with us!</p>
              </div>
            </div>
            <div className="col-md-6">
             
              <form action method>
                <fieldset className="home-form-group">
                  <label htmlFor="formName">Your Name:</label>
                  <input
                    id="formName"
                    className="home-form-control"
                    type="text"
                    placeholder="Your Name"
                    required
                  />
                </fieldset>
                <fieldset className="home-form-group">
                  <label htmlFor="formEmail1">Email address:</label>
                  <input
                    id="formEmail1"
                    className="home-form-control"
                    type="email"
                    placeholder="Enter email"
                    required
                  />
                </fieldset>
                <fieldset className="home-form-group">
                  <label htmlFor="formMessage">Your Message:</label>
                  <textarea
                    id="formMessage"
                    className="home-form-control"
                    rows={3}
                    placeholder="Your message"
                    required
                    defaultValue={""}
                  />
                </fieldset>
                <fieldset className="home-form-group text-center">
                  <button className=" btn btn-sendmessage" type="submit">
                    Send Message
                  </button>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </section> */}

      <div className="home-cards-container">
        <div className="home-card">
          <div
            className="home-card-img"
            style={{
              backgroundImage:
                "url(https://cdn.prod.website-files.com/614c82ed388d53640613982e/64f7989c55786e5b4de9b9cb_sentiment-analysis-explained.webp)",
            }}
          >
            <div className="home-overlay">
              <div className="home-overlay-content"></div>
            </div>
          </div>
          <div className="home-card-content">
            <a href="#!">
              <h2>Sentiment Analysis</h2>
              <p>
                Leverage our cloud-native text analytics platform for scalable,
                efficient, and real-time data processing and insights
                generation.
              </p>
            </a>
          </div>
        </div>
        <div className="home-card">
          <div
            className="home-card-img"
            style={{
              backgroundImage:
                "url(https://thumbs.dreamstime.com/b/wall-filled-various-data-charts-graphs-statistics-illustrating-complex-data-analysis-research-wall-covered-320487181.jpg)",
            }}
          >
            <div className="home-overlay">
              <div className="home-overlay-content"></div>
            </div>
          </div>
          <div className="home-card-content">
            <a href="#!">
              <h2>Thematic Analysis </h2>
              <p>
                Protect sensitive information with our advanced PII detection
                tool, ensuring compliance and data security across your text
                documents.
              </p>
            </a>
          </div>
        </div>
        <div className="home-card">
          <div
            className="home-card-img"
            style={{
              backgroundImage: "url(https://www.dasera.com/hubfs/PII%201.webp)",
            }}
          >
            <div className="home-overlay">
              <div className="home-overlay-content"></div>
            </div>
          </div>
          <div className="home-card-content">
            <a href="#!">
              <h2> PII GenAI </h2>
              <p>
                Gain deeper insights into customer satisfaction with our CSAT
                analysis tool, helping you improve products and services based
                on feedback.
              </p>
            </a>
          </div>
        </div>
        <div className="home-card">
          <div
            className="home-card-img"
            style={{
              backgroundImage:
                "url(https://images.ctfassets.net/a8pkciehvm1g/7bZju8whzq2FldF2tP7X6Q/9d90f58605fe86fabdfe3db7a6bf0030/_1200x675__4Q231101_-_Real-time_translation_guide_-_3_tools_and_4_best_practices_-_1.jpg?fl=progressive&fm=jpg&h=343&q=50&w=610)",
            }}
          >
            <div className="home-overlay">
              <div className="home-overlay-content"></div>
            </div>
          </div>
          <div className="home-card-content">
            <a href="#!">
              <h2>Text Translation</h2>
              <p>
                Harness the power of web data with our intelligent scraping
                tools, enabling you to collect and analyze vast amounts of
                online information.
              </p>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default Home;
