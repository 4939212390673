import React from "react";

const EmptyState = () => (
  <div className="empty-state">
    <svg className="empty-icon" viewBox="0 0 100 100" width="100" height="100">
      <circle
        cx="50"
        cy="50"
        r="45"
        stroke="rgb(146, 98, 201)"
        strokeWidth="8"
        fill="none"
      />
      <line
        x1="20"
        y1="50"
        x2="80"
        y2="50"
        stroke="rgb(146, 98, 201)"
        strokeWidth="8"
      />
    </svg>
    <p className="empty-message">Oops! No users found.</p>
    <p className="empty-submessage">Add a new user to get started!</p>
  </div>
);

export default EmptyState;
