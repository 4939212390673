import React, { useState, useEffect, useRef } from "react"
import { getUserProfile, updateUserProfile, uploadProfileImage, getDefaultProfileImage } from "./apiService"
import PopupMessage from "./PopupMessage"
import { FaCamera } from "react-icons/fa"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import "./Profile.css"
import Menu from "./Menu"
import ChangePassword from "./ChangePassword"
import { NavLink } from "react-router-dom"

function Profile() {
  const [firstName, setFirstName] = useState("")
  const [middleName, setMiddleName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [designation, setDesignation] = useState("")
  const [company, setCompany] = useState("")
  const [role, setRole] = useState("")
  const [profileImage, setProfileImage] = useState("")
  const [isEditing, setIsEditing] = useState(false)
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [originalFirstName, setOriginalFirstName] = useState("")
  const [originalMiddleName, setOriginalMiddleName] = useState("")
  const [originalLastName, setOriginalLastName] = useState("")
  const [originalPhone, setOriginalPhone] = useState("")
  const [originalDesignation, setOriginalDesignation] = useState("")
  const [originalCompany, setOriginalCompany] = useState("")
  const [originalRole, setOriginalRole] = useState("")
  const [imageFile, setImageFile] = useState(null)
  const [popupMessage, setPopupMessage] = useState("")
  const [showPopup, setShowPopup] = useState(false)
  const [imageKey, setImageKey] = useState(Date.now())

  const fileInputRef = useRef(null)

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profileData = await getUserProfile()

        // Set the fields directly as separate values
        setFirstName(profileData.first_name || "")
        setMiddleName(profileData.middle_name || "")
        setLastName(profileData.last_name || "")

        setOriginalFirstName(profileData.first_name || "")
        setOriginalMiddleName(profileData.middle_name || "")
        setOriginalLastName(profileData.last_name || "")

        setEmail(profileData.email || "example@example.com")
        setPhone(profileData.phone || "")
        setDesignation(profileData.designation || "")
        setCompany(profileData.company || "")
        setRole(profileData.role || "")

        setOriginalPhone(profileData.phone || "")
        setOriginalDesignation(profileData.designation || "")
        setOriginalCompany(profileData.company || "")
        setOriginalRole(profileData.role || "")

        if (profileData.profile_picture) {
          setProfileImage(`data:image/png;base64,${profileData.profile_picture}`)
        } else {
          const defaultImage = await getDefaultProfileImage()
          setProfileImage(`data:image/png;base64,${defaultImage.profile_picture}`)
        }
        setImageKey(Date.now())
      } catch (error) {
        console.error("Error fetching user profile:", error.message)
      }
    }

    fetchUserProfile()
  }, [])

  const handleEdit = () => {
    setIsEditing(true)
    setOriginalFirstName(firstName)
    setOriginalMiddleName(middleName)
    setOriginalLastName(lastName)
    setOriginalPhone(phone)
    setOriginalDesignation(designation)
    setOriginalCompany(company)
    setOriginalRole(role)
  }

  const handleSave = async () => {
    try {
      const userData = {
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        phone: phone,
        designation: designation,
        company: company
      }
      await updateUserProfile(userData)
      setPopupMessage("Profile Updated successfully")
      setShowPopup(true)
      setTimeout(() => setShowPopup(false), 5000)
      setIsEditing(false)
    } catch (error) {
      setPopupMessage("Error Updating Profile")
      setShowPopup(true)
      setTimeout(() => setShowPopup(false), 5000)
    }
  }

  const handleCancel = () => {
    setFirstName(originalFirstName)
    setMiddleName(originalMiddleName)
    setLastName(originalLastName)
    setPhone(originalPhone)
    setDesignation(originalDesignation)
    setCompany(originalCompany)
    setRole(originalRole)
    setIsEditing(false)
  }

  const handleImageChange = e => {
    const file = e.target.files[0]
    if (file) {
      setImageFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setProfileImage(reader.result)
      }
      reader.readAsDataURL(file)
    } else {
      console.error("No file selected or file is invalid")
    }
  }

  const triggerFileInput = () => {
    fileInputRef.current.click()
  }

  const uploadImage = async () => {
    if (imageFile) {
      const reader = new FileReader()
      reader.onloadend = async () => {
        try {
          const base64Image = reader.result.split(",")[1]
          const response = await uploadProfileImage({ profile_picture: base64Image })
          if (response.message === "Profile picture updated successfully") {
            // Fetch the updated profile data
            const updatedProfileData = await getUserProfile()
            if (updatedProfileData.profile_picture) {
              setProfileImage(`data:image/png;base64,${updatedProfileData.profile_picture}`)
              setImageKey(Date.now())
            }
          } else {
            console.error("Unexpected response from server")
          }
          setImageFile(null)
          setPopupMessage("Profile Picture Updated successfully")
          setShowPopup(true)
          setTimeout(() => setShowPopup(false), 5000)
        } catch (error) {
          console.error("Error uploading profile image:", error.response ? error.response.data : error.message)
          setPopupMessage("Error Updating Profile Picture")
          setShowPopup(true)
          setTimeout(() => setShowPopup(false), 5000)
        }
      }
  
      reader.readAsDataURL(imageFile)
    }
  }

  const handleChangePasswordClick = () => {
    setShowChangePassword(true)
  }

  const closeChangePasswordModal = () => {
    setShowChangePassword(false)
  }

  return (
    <section className="profile-section">
    <Menu />
    <nav className="navbar navbar-light bg-body-tertiary custom-navbar">
      <div className="container-fluid">
        <NavLink className="navbar-brand gradient-text" to="#">
          Profile
        </NavLink>
      </div>
    </nav>
    <div className="profile-container">
      <div className="profile-content">
        <div className="profile-fields">
          {/* First Row */}
          <div className="row mb-4">
            <div className="col-sm-6">
              <strong>First Name</strong>
              <input type="text" className="form-control" value={firstName} onChange={e => setFirstName(e.target.value)} disabled={!isEditing} />
            </div>
            <div className="col-sm-6">
              <strong>Last Name</strong>
              <input type="text" className="form-control" value={lastName} onChange={e => setLastName(e.target.value)} disabled={!isEditing} />
            </div>
          </div>

          {/* Second Row */}
          <div className="row mb-4">
            <div className="col-sm-6">
              <strong>Middle Name</strong>
              <input type="text" className="form-control" value={middleName} onChange={e => setMiddleName(e.target.value)} disabled={!isEditing} />
            </div>
            <div className="col-sm-6">
              <strong>Email</strong>
              <input type="text" className="form-control" value={email} readOnly />
            </div>
          </div>

          {/* Third Row */}
          <div className="row mb-4">
            <div className="col-sm-6">
              <strong>Phone</strong>
              <PhoneInput country={"us"} value={phone} onChange={phone => setPhone(phone)} disabled={!isEditing} inputClass="form-control custom-phone-input" />
            </div>
            <div className="col-sm-6">
              <strong>Designation</strong>
              <input type="text" className="form-control" value={designation} onChange={e => setDesignation(e.target.value)} disabled={!isEditing} />
            </div>
          </div>

          {/* Fourth Row */}
          <div className="row mb-4">
            <div className="col-sm-6">
              <strong>Company</strong>
              <input type="text" className="form-control" value={company} onChange={e => setCompany(e.target.value)} disabled={!isEditing} />
            </div>
            <div className="col-sm-6">
              <strong>Role</strong>
              <input type="text" className="form-control" value={role} readOnly />
            </div>
          </div>

          <div className="profile-actions">
            {!isEditing ? (
              <button className="btn btn-outline-primary" onClick={handleEdit}>
                Edit Profile
              </button>
            ) : (
              <>
                <button className="btn btn-outline-success me-2" onClick={handleSave}>
                  Save
                </button>
                <button className="btn btn-outline-secondary" onClick={handleCancel}>
                  Cancel
                </button>
              </>
            )}
          </div>
          
          <div className="change-password-section">
            <hr />
            <p className="change-password-text" onClick={handleChangePasswordClick}>
              Change Password
            </p>
            <hr />
          </div>
        </div>
      </div>
      <div className="profile-avatar">
        <div className="avatar-container" onClick={triggerFileInput}>
          <img 
            src={profileImage || "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg=="} 
            alt="avatar" 
            className="avatar-image" 
            key={imageKey}
          />
          <div className="avatar-hover">
            <FaCamera className="avatar-hover-icon" />
          </div>
        </div>
        <input type="file" accept="image/*" ref={fileInputRef} onChange={handleImageChange} style={{ display: "none" }} />
        {imageFile && (
          <button className="btn btn-outline-primary mt-2" onClick={uploadImage}>
            Upload Image
          </button>
        )}
        <p className="text-muted mb-4 center-text">{`${firstName} ${lastName}`}</p>
      </div>
    </div>
    {showChangePassword && <ChangePassword showModal={showChangePassword} closeModal={closeChangePasswordModal} />}
    {showPopup && <PopupMessage message={popupMessage} onClose={() => setShowPopup(false)} />}
  </section>
  )
}

export default Profile
