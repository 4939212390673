import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import { loginUser } from "./apiService";
import PopupMessage from "./PopupMessage";
import "./Login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Add this state
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoggingIn(true);

    const userData = { email: email, password: password };

    try {
      const response = await loginUser(userData);
      console.log("Login response:", response);

      if (
        response.status === "success" &&
        response.access_token &&
        response.refresh_token
      ) {
        localStorage.setItem("accessToken", response.access_token);
        localStorage.setItem("refreshToken", response.refresh_token);
        localStorage.setItem("userEmail", email);
        localStorage.setItem("userName", response.username);

        setPopupMessage("Login successful.");
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 5000);

        setTimeout(() => {
          // After successful login
          navigate("/main", { state: { userName: response.username } });
        }, 1000);
      } else {
        throw new Error(response.message || "Unknown error occurred");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Login Failed. Please Try again.";
      setErrorMessage(errorMessage); // Update the error message
      setTimeout(() => setErrorMessage(""), 4000);
      // Display the error message in the popup
    } finally {
      setIsLoggingIn(false); // Set loading state back to false when login process ends
    }
  };

  const showPassHandle = () => {
    setShowPass(!showPass);
  };

  return (
    <section className="login-page">
      <div className="login-background"></div>
      <div className="login-container">
        <div className="login-content">
          <div className="su-start-title">
            <span className="su-start-title-txt brand-name-login">
              KakarotGenics
            </span>
            <p className="su-start-hdng mt-3 mb-5 brand-name-login-text">
              Log in to your account !
            </p>
          </div>
          {errorMessage && ( // Conditionally render the error message
            <div className="error-message">
              <i className="bi bi-exclamation-triangle error-icon"></i>
              {errorMessage}
            </div>
          )}
          <form onSubmit={handleSubmit} className="login-form">
            <div className="login-form-group">
              <label htmlFor="email" className="lg-label">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-100 lg-inp"
                name="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <div className="login-form-group">
              <label htmlFor="password" className="lg-label">
                Password
              </label>
              <div className="password-input-container">
                <input
                  type={showPass ? "text" : "password"}
                  id="password"
                  className="w-100 lg-inp"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
                <div className="eye-cnt" onClick={showPassHandle}>
                  {showPass ? (
                    <i className="bi bi-eye-slash"></i>
                  ) : (
                    <i className="bi bi-eye"></i>
                  )}
                </div>
              </div>
            </div>
            <div
              className="login-form-group"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              <button
                type="submit"
                className="btn-btn-login"
                disabled={isLoggingIn}
              >
                {isLoggingIn ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                ) : (
                  "Log in"
                )}
              </button>
              <p className="forgot-link" style={{ marginTop: "10px" }}>
                or{" "}
                <NavLink to="/forgot-password" className="purple-link">
                  Forgot Password
                </NavLink>
              </p>
              <p style={{ marginTop: "10px" }}>
                Don't have an account?{" "}
                <NavLink to="/register" className="purple-link">
                  Sign Up
                </NavLink>
              </p>
            </div>
          </form>
        </div>
      </div>
      {showPopup && (
        <PopupMessage
          message={popupMessage}
          onClose={() => setShowPopup(false)}
        />
      )}
    </section>
  );
}

export default Login;
