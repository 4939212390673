import React, { useState, useEffect } from "react";
import { Users, X, UserPlus } from "lucide-react";
import Select from "react-select";

const UserManagement = ({
  taskId,
  initialUsers,
  allUsers,
  onAddUsers,
  onRemoveUsers,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAddUsers, setShowAddUsers] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentUsers, setCurrentUsers] = useState(initialUsers || []);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const availableUsers = allUsers.filter(
    (user) => !currentUsers.find((currentUser) => currentUser.id === user.value)
  );

  const handleAddUsers = async () => {
    if (selectedUsers.length > 0) {
      await onAddUsers(
        taskId,
        selectedUsers.map((user) => user.value)
      );
      setCurrentUsers([
        ...currentUsers,
        ...selectedUsers.map((user) => ({
          id: user.value,
          username: user.label.split(" (")[0],
          email: user.label.split(" (")[1].slice(0, -1),
        })),
      ]);
      setSelectedUsers([]);
      setShowAddUsers(false);
    }
  };
  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/view-sentiment-users/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({ task_id: taskId }),
        }
      );

      if (!response.ok) throw new Error("Failed to fetch users");

      const data = await response.json();
      setCurrentUsers(data.users_with_access || []);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchUsers();
    }
  }, [isModalOpen, taskId]);
  const handleRemoveUser = async (userId) => {
    await onRemoveUsers(taskId, [userId]);
    setCurrentUsers(currentUsers.filter((user) => user.id !== userId));
  };

  if (!isModalOpen) {
    return (
      <div
        className="flex items-center cursor-pointer"
        onClick={() => setIsModalOpen(true)}
      >
        <Users size={18} className="text-gray-600" />
        <span className="ml-1 text-sm text-gray-600">
          {currentUsers.length}
        </span>
      </div>
    );
  }

  return (
    <div className="modals-container">
      <div className="modals-wrapper">
        <div className="participants-modal">
          <div className="modal-header">
            <h5 className="modal-title">People ({currentUsers.length})</h5>
            <button
              className="btn-close"
              onClick={() => setIsModalOpen(false)}
              aria-label="Close"
            >
              <X size={16} />
            </button>
          </div>

          <div className="modal-body p-0">
            {currentUsers.length > 0 ? (
              <div className="users-list-container">
                {currentUsers.map((user) => (
                  <div key={user.id} className="user-item">
                    <div className="user-info">
                      <div className="user-avatar">
                        {user.username[0]?.toUpperCase()}
                      </div>
                      <div className="user-text">
                        <p className="user-name">{user.username}</p>
                        <small className="user-email">{user.email}</small>
                      </div>
                    </div>
                    <button
                      onClick={() => handleRemoveUser(user.id)}
                      className="remove-user-btn"
                    >
                      <X size={16} />
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <div className="empty-state">No users added yet</div>
            )}

            <div className="add-people-section">
              <button
                onClick={() => setShowAddUsers(true)}
                className="add-people-btn"
              >
                <UserPlus size={16} />
                <span>Add people</span>
              </button>
            </div>
          </div>
        </div>

        {showAddUsers && (
          <div className="add-users-modal">
            <div className="modal-header">
              <h3>Add Users</h3>
            </div>
            <div className="select-container">
              <Select
                isMulti
                options={availableUsers}
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedUsers}
                onChange={setSelectedUsers}
                placeholder="Enter a name or email"
                formatOptionLabel={(option) => (
                  <div className="user-option">
                    <div className="user-avatar">
                      {option.label.split(" (")[0][0]?.toUpperCase()}
                    </div>
                    <div className="user-option-details">
                      <div className="user-option-name">
                        {option.label.split(" (")[0]}
                      </div>
                      <div className="user-option-email">
                        {option.label.split(" (")[1].slice(0, -1)}
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="button-container">
              <button
                className="cancel-btn"
                onClick={() => setShowAddUsers(false)}
              >
                Cancel
              </button>
              <button
                className="add-btn"
                onClick={handleAddUsers}
                disabled={selectedUsers.length === 0}
              >
                Add
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserManagement;
