import React, { useState, useEffect } from "react";
import { X, UserPlus, Plus } from "lucide-react";
import "./ParticipantsModal.css";
import Select from "react-select";

const ParticipantsModal = ({ isOpen, onClose }) => {
  const [currentUsers, setCurrentUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [showAddPeople, setShowAddPeople] = useState(false);
  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [selectedUsersToAdd, setSelectedUsersToAdd] = useState([]);

  useEffect(() => {
    if (isOpen) {
      fetchCurrentUsers();
    }
  }, [isOpen]);

  const fetchCurrentUsers = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await fetch(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/cloud-native-users/",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setCurrentUsers(data.users || []);
    } catch (error) {
      console.error("Error fetching current users:", error);
    }
  };

  const fetchAvailableUsers = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await fetch(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/fetch-available-cloud-native-users/",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setAvailableUsers(data.available_users || []);
    } catch (error) {
      console.error("Error fetching available users:", error);
    }
  };

  const handleShowAddPeople = async () => {
    await fetchAvailableUsers();
    setShowAddPeople(true);
  };

  const handleRemoveUser = async (userId) => {
    try {
      const formData = new FormData();
      formData.append("user_ids", userId);

      const response = await fetch(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/cloud-native-users/",
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        await fetchCurrentUsers();
      } else {
        const errorData = await response.json();
        console.error("Error removing user:", errorData);
      }
    } catch (error) {
      console.error("Error removing user:", error);
    }
  };

  const handleAddUser = async (userId) => {
    try {
      const formData = new FormData();
      formData.append("user_ids", userId);

      const response = await fetch(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/cloud-native-users/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        await fetchCurrentUsers();
        await fetchAvailableUsers();
      } else {
        const errorData = await response.json();
        console.error("Error adding user:", errorData);
      }
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const handleShowAddUsers = async () => {
    await fetchAvailableUsers();
    setShowAddUsersModal(true);
  };

  const handleAddUsers = async () => {
    try {
      for (const user of selectedUsersToAdd) {
        await handleAddUser(user.value);
      }
      setShowAddUsersModal(false);
      setSelectedUsersToAdd([]);
    } catch (error) {
      console.error("Error adding users:", error);
    }
  };

  if (!isOpen) return null;

  // Transform available users for react-select
  const selectOptions = availableUsers.map((user) => ({
    value: user.id,
    label: `${user.username} (${user.email})`,
    userData: user,
  }));

  return (
    <div className="modals-container">
      <div className="modals-wrapper">
        {/* Participants Modal */}
        <div className="participants-modal">
          <div className="modal-header">
            <h5 className="modal-title">People ({currentUsers.length})</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body p-0">
            {currentUsers.length > 0 ? (
              <div className="users-list-container">
                {currentUsers.map((user) => (
                  <div key={user.id} className="user-item">
                    <div className="user-info">
                      <div className="user-avatar">
                        {user.username?.[0]?.toUpperCase()}
                      </div>
                      <div className="user-text">
                        <p className="user-name">{user.username}</p>
                        <small className="user-email">{user.email}</small>
                      </div>
                    </div>
                    <button
                      onClick={() => handleRemoveUser(user.id)}
                      className="remove-user-btn"
                    >
                      <X size={16} />
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <div className="empty-state">No users added yet</div>
            )}

            <div className="add-people-section">
              <button onClick={handleShowAddUsers} className="add-people-btn">
                <UserPlus size={16} />
                <span>Add people</span>
              </button>
            </div>
          </div>
        </div>

        {/* Add Users Modal */}
        {showAddUsersModal && (
          <div className="add-users-modal">
            <div className="modal-header">
              <h3>Add Users</h3>
              {/* <button
                className="close-btn"
                onClick={() => setShowAddUsersModal(false)}
              >
                ×
              </button> */}
            </div>
            <div className="select-container">
              <Select
                isMulti
                options={selectOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedUsersToAdd}
                onChange={setSelectedUsersToAdd}
                placeholder="Enter a name or email"
                formatOptionLabel={(option) => (
                  <div className="user-option">
                    <div
                      style={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                        backgroundColor: "#f0f0f0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexShrink: 0,
                      }}
                    >
                      {option.userData?.username?.[0]?.toUpperCase()}
                    </div>
                    <div className="user-option-details">
                      <div className="user-option-name">
                        {option.userData?.username}
                      </div>
                      <div className="user-option-email">
                        {option.userData?.email}
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="button-container">
              <button
                className="cancel-btn"
                onClick={() => setShowAddUsersModal(false)}
              >
                Cancel
              </button>
              <button
                className="add-btn"
                onClick={handleAddUsers}
                disabled={selectedUsersToAdd.length === 0}
              >
                Add
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ParticipantsModal;
