import axios from "axios";

const API_URL =
  "https://kakarotgenics-django-intermediate.azurewebsites.net/api/";

const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}register/`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const loginUser = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}login/`, userData);
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Backend response error:", error.response.data);
    } else {
      console.error("Error logging in:", error.message);
    }
    throw error;
  }
};

const refreshAccessToken = async (refreshToken) => {
  try {
    const response = await axios.post(`${API_URL}token/refresh/`, {
      refresh: refreshToken,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getUserProfile = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.get(`${API_URL}profile/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateUserProfile = async (userProfile) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(`${API_URL}profile/`, userProfile, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const uploadProfileImage = async (imageData) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(`${API_URL}profile-picture/`, imageData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getDefaultProfileImage = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.get(`${API_URL}profile-picture/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const changePassword = async (passwordData) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}change_password/`,
      passwordData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const addUser = async (userData) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(`${API_URL}add_user/`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getUsers = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.get(`${API_URL}users/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteUser = async (userData) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(`${API_URL}delete-user/`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const requestPasswordResetOTP = async (email) => {
  try {
    const response = await axios.post(`${API_URL}request-password-reset-otp/`, {
      email,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const resetPassword = async (email, otp, newPassword1, newPassword2) => {
  try {
    const response = await axios.post(`${API_URL}password-reset-confirm/`, {
      email,
      otp,
      new_password1: newPassword1,
      new_password2: newPassword2,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
const createProject = async (projectData) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("project_id", projectData.id);
    formData.append("project_name", projectData.name);
    formData.append("project_type", projectData.type);
    formData.append("description", projectData.description);

    // Append visible_to for each selected user
    if (Array.isArray(projectData.selectedUsers)) {
      projectData.selectedUsers.forEach((userId) => {
        formData.append("visible_to", userId);
      });
    }

    const response = await axios.post(`${API_URL}create-project/`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error creating project:", error);
    throw error;
  }
};

// Function to create a Cloud Native Task
const createCloudNativeTask = async (taskData) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();

    // Append the required fields for the task
    formData.append("task_name", taskData.name);
    formData.append("task_description", taskData.description);

    const response = await axios.post(
      `${API_URL}create-cloud-native-task/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error creating cloud-native task:", error);
    throw error;
  }
};
export const deleteCloudNativeTask = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("task_id", taskId);

    const response = await axios.post(
      `${API_URL}delete-cloud-native-task/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting cloud native task:", error);
    throw error;
  }
};
const updateProject = async (projectId, projectData) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("project_id", projectId); // Ensure project_id is included

    if (projectData.name) {
      formData.append("project_name", projectData.name);
    }
    if (projectData.description) {
      formData.append("project_description", projectData.description);
    }
    if (projectData.file) {
      formData.append("excel_file", projectData.file);
    }

    const response = await axios.post(`${API_URL}update-project/`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating project:", error);
    throw error;
  }
};

const deleteProject = async (projectType) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("project_type", projectType);

    const response = await axios.post(`${API_URL}delete-project/`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting project:", error);
    throw error;
  }
};
const getProjects = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.get(`${API_URL}get-cloud-native-tasks/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.tasks.map((task) => ({
      id: task.task_id,
      project_name: task.task_name,
      project_description: task.task_description,
      created_by: task.created_by,
      created_time: task.created_at || new Date().toISOString(),
    }));
  } catch (error) {
    console.error("Error fetching projects:", error);
    throw error;
  }
};
const uploadVerbatimFile = async (taskId, file) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("task_id", taskId);
    formData.append("verbatim_file", file);

    const response = await axios.post(
      `${API_URL}upload-verbatim-file/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error uploading verbatim file:", error);
    throw error;
  }
};

const selectSheetFromVerbatim = async (taskId, sheetName) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("task_id", taskId);
    formData.append("sheet_name", sheetName);

    const response = await axios.post(
      `${API_URL}select-sheet-from-verbatim/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error selecting sheet from verbatim:", error);
    throw error;
  }
};

const selectColumnFromVerbatim = async (taskId, columnName) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("task_id", taskId);
    formData.append("selected_column", columnName);

    const response = await axios.post(
      `${API_URL}select-column-from-verbatim/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error selecting column from verbatim:", error);
    throw error;
  }
};
const uploadcodeframeFile = async (taskId, file) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("task_id", taskId);
    formData.append("codeframe_file", file);

    const response = await axios.post(
      `${API_URL}upload-codeframe-file/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error uploading Codeframe file:", error);
    throw error;
  }
};

const selectSheetFromCodeframe = async (taskId, sheetName) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("task_id", taskId);
    formData.append("sheet_name", sheetName);

    const response = await axios.post(
      `${API_URL}select-sheet-from-codeframe/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error selecting sheet from Codeframe:", error);
    throw error;
  }
};

const selectColumnFromCodeframe = async (taskId, columnName) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("task_id", taskId);
    formData.append("selected_column", columnName);

    const response = await axios.post(
      `${API_URL}select-column-from-codeframe/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error selecting column from Codeframe:", error);
    throw error;
  }
};
const runAnalysis = async (taskId) => {
  if (!taskId) {
    throw new Error("Project ID is required to run analysis.");
  }

  try {
    const token = localStorage.getItem("accessToken");

    const response = await axios.post(
      `${API_URL}run-analysis/`,
      { task_id: taskId }, // Pass project ID in the body
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error running analysis:", error);
    throw error;
  }
};
const getUploadedData = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}get-cloud-native-task-detail/`,
      { task_id: taskId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const createPiiDetectionTask = async (taskData) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();

    formData.append("task_name", taskData.task_name);
    formData.append("task_description", taskData.task_description);

    const response = await axios.post(
      `${API_URL}create-pii-detection-task/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data; // This should now include all task details
  } catch (error) {
    console.error("Error creating PII detection task:", error);
    throw error;
  }
};

const updatePiiProject = async (projectId, projectData) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("project_id", projectId); // Ensure project_id is included

    if (projectData.name) {
      formData.append("project_name", projectData.name);
    }
    if (projectData.description) {
      formData.append("project_description", projectData.description);
    }
    if (projectData.file) {
      formData.append("excel_file", projectData.file);
    }

    const response = await axios.post(
      `${API_URL}update-pii-project/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating project:", error);
    throw error;
  }
};

export const deletePiiDetectionTask = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("task_id", taskId);

    const response = await axios.post(
      `${API_URL}delete-pii-detection-task/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting PII detection task:", error);
    throw error;
  }
};
const getPiiProjects = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      throw new Error("No access token found");
    }

    const response = await axios.get(`${API_URL}get-pii-detection-tasks/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log("API Response:", response.data);

    if (!response.data.tasks || !Array.isArray(response.data.tasks)) {
      throw new Error(
        "Invalid response format: tasks is missing or not an array"
      );
    }

    return response.data.tasks;
  } catch (error) {
    console.error("Error fetching PII projects:", error);
    throw error;
  }
};

const uploadPiiFile = async (taskId, file) => {
  try {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      throw new Error("No access token found");
    }

    const formData = new FormData();
    formData.append("task_id", taskId);
    formData.append("pii_file", file);

    console.log("Uploading PII file for task ID:", taskId);

    const response = await axios.post(
      `${API_URL}pii-detection/upload-file/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("Upload PII file response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error uploading PII Detector file:", error);
    if (error.response) {
      console.error("Error response:", error.response.data);
      console.error("Status code:", error.response.status);
    }
    throw error;
  }
};
const selectSheetFromPii = async (taskId, sheetName) => {
  try {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      throw new Error("No access token found");
    }

    console.log(`Selecting sheet "${sheetName}" for task ID: ${taskId}`);

    const response = await axios.post(
      `${API_URL}pii-detection/select-sheet/`,
      { task_id: taskId, sheet_name: sheetName },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Select sheet response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error selecting sheet from Pii Detector:", error);
    if (error.response) {
      console.error("Error response:", error.response.data);
      console.error("Status code:", error.response.status);
    }
    throw error;
  }
};

const selectColumnFromPii = async (taskId, columnName) => {
  try {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      throw new Error("No access token found");
    }

    console.log(`Selecting column "${columnName}" for task ID: ${taskId}`);

    const response = await axios.post(
      `${API_URL}pii-detection/select-column/`,
      { task_id: taskId, selected_column: columnName },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Select column response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error selecting column from Pii Detector:", error);
    if (error.response) {
      console.error("Error response:", error.response.data);
      console.error("Status code:", error.response.status);
    }
    throw error;
  }
};
const getUploadedPiiData = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      throw new Error("No access token found");
    }

    console.log("Fetching PII data for task ID:", taskId);

    const response = await axios.post(
      `${API_URL}get-pii-detection-task-detail/`,
      { task_id: taskId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log("PII task details response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching PII task details:", error);
    if (error.response) {
      console.error("Error response:", error.response.data);
      console.error("Status code:", error.response.status);
    }
    throw error;
  }
};
const getUserProjects = async () => {
  try {
    const token = localStorage.getItem("accessToken"); // Retrieve the token from localStorage

    const response = await axios.get(`${API_URL}get-user-projects/`, {
      headers: {
        Authorization: `Bearer ${token}`, // Include the Bearer token in the request headers
      },
    });

    return response.data.projects; // Return the list of projects from the response
  } catch (error) {
    console.error("Error fetching user projects:", error);
    throw error;
  }
};

const getCostEstimation = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}estimate-cost/`,
      { task_id: taskId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting cost estimation:", error);
    throw error;
  }
};

export const fetctCostEstimationReport = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}get-cost-estimation-report/`,
      { task_id: taskId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTaskStatus = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}get-task-status/`,
      { task_id: taskId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting task status:", error);
    throw error;
  }
};

export const getAllUsers = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.get(`${API_URL}get-all-users/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.map((user) => ({
      id: user.id,
      username: user.username,
      email: user.email,
      profilePicture: user.profile_picture, // Include the profile picture from the backend
    }));
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const runPiiAnalysis = async (taskId) => {
  if (!taskId) {
    throw new Error("Task ID is required to run PII analysis.");
  }

  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}run-pii-analysis/`,
      { task_id: taskId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error running PII analysis:", error);
    throw error;
  }
};

export const getPiiTaskStatus = async (taskId) => {
  try {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      throw new Error("No access token found");
    }

    console.log("Fetching PII task status for task ID:", taskId);

    const response = await axios.post(
      `${API_URL}get-pii-task-status/`,
      { task_id: taskId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log("PII task status response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error getting PII task status:", error);
    if (error.response) {
      console.error("Error response:", error.response.data);
      console.error("Status code:", error.response.status);
    }
    throw error;
  }
};

export const getCloudNativeAccessUsers = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.get(
      `${API_URL}get-cloud-native-access-users/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching cloud native access users:", error);
    throw error;
  }
};

export const getAzureCsatAccessUsers = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.get(`${API_URL}get-azure-csat-access-users/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching Azure CSAT access users:", error);
    throw error;
  }
};

export const getPiiDetectionAccessUsers = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.get(
      `${API_URL}get-pii-detection-access-users/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching PII detection access users:", error);
    throw error;
  }
};

// ... (existing imports and API calls)

export const addCloudNativeUsers = async (projectId, userIds) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}add-cloud-native-users/`,
      { project_id: projectId, user_ids: userIds },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding cloud native users:", error);
    throw error;
  }
};

export const fetchAvailableCloudNativeUsers = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.get(
      `${API_URL}fetch-available-cloud-native-users/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching available cloud native users:", error);
    throw error;
  }
};

export const runSentimentAnalysis = async (taskId) => {
  if (!taskId) {
    throw new Error("Task ID is required to run PII analysis.");
  }

  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${API_URL}run-sentimental-analysis-task/`,
      { task_id: taskId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error running PII analysis:", error);
    throw error;
  }
};

// ... (rest of the file)

export {
  registerUser,
  loginUser,
  refreshAccessToken,
  getUserProfile,
  updateUserProfile,
  uploadProfileImage,
  getDefaultProfileImage,
  changePassword,
  addUser,
  getUsers,
  deleteUser,
  requestPasswordResetOTP,
  resetPassword,
  createProject,
  updateProject,
  deleteProject,
  getProjects,
  uploadVerbatimFile,
  selectSheetFromVerbatim,
  selectColumnFromVerbatim,
  uploadcodeframeFile,
  selectColumnFromCodeframe,
  selectSheetFromCodeframe,
  runAnalysis,
  getUploadedData,
  createPiiDetectionTask,
  getPiiProjects,
  updatePiiProject,
  uploadPiiFile,
  selectSheetFromPii,
  selectColumnFromPii,
  getUploadedPiiData,
  createCloudNativeTask,
  getUserProjects,
  getCostEstimation,
};
