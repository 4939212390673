import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Menu from "./Menu";
import "./Users.css";
import { addUser, getUsers, deleteUser } from "./apiService";
import { NavLink } from "react-router-dom";
import PopupMessage from "./PopupMessage";
import ConfirmationModal from "./ConfirmationModal";
import EmptyState from "./EmptyState";
import UnauthorizedAccess from "./UnauthorizedAccess";

function Users() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const data = await getUsers();
        if (Array.isArray(data.data)) {
          setUsers(data.data);
          setIsAdmin(true);
        } else {
          console.error("Fetched data is not an array:", data);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        if (error.response && error.response.status === 403) {
          setIsAdmin(false);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleAddUser = async e => {
    e.preventDefault();
    setIsAddingUser(true);
    try {
      await addUser({ email, username: name, password });
      setUsers([...users, { first_name: name, middle_name: null, last_name: null, email, password, phone: null, company: null, designation: null }]);
      setName("");
      setEmail("");
      setPassword("");
      setShowModal(false);
      setPopupMessage("New user has been added.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 1000);
    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "Failed to add user.";
      setPopupMessage(errorMessage);
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);
      console.error("Error adding user:", error);
    } finally {
      setIsAddingUser(false);
    }
  };


  const handleDeleteUsers = async () => {
    try {
      const promises = selectedUsers.map(async user => {
        const payload = { email: user.email };
        await deleteUser(payload);
      });
      await Promise.all(promises);

      const remainingUsers = users.filter(user => !selectedUsers.includes(user));
      setUsers(remainingUsers);
      setSelectedUsers([]);
      setPopupMessage("Selected users have been deleted.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 1000);
    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "Failed to delete users.";
      setPopupMessage(errorMessage);
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 1000);
      console.error("Error deleting users:", error);
    }
  };
  

  const confirmDeleteUsers = () => {
    if (selectedUsers.length === 0) {
      setPopupMessage("Please select the users to delete.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);
      return;
    }
    setShowConfirmModal(true);
  };
  

  const handleConfirmDelete = () => {
    setShowConfirmModal(false);
    handleDeleteUsers();
  };

  const handleCancelDelete = () => {
    setShowConfirmModal(false);
  };

  const handleCheckboxChange = (user, checked) => {
    if (checked) {
      setSelectedUsers([...selectedUsers, user]);
    } else {
      setSelectedUsers(selectedUsers.filter(selectedUser => selectedUser !== user));
    }
  };

  const handleSelectAll = checked => {
    if (checked) {
      setSelectedUsers(users);
    } else {
      setSelectedUsers([]);
    }
  };

  const openModal = () => {
    setShowModal(true);
    document.body.classList.add("users-modal-open");
  };

  const closeModal = () => {
    setShowModal(false);
    document.body.classList.remove("users-modal-open");
  };
  if (isLoading) {
    return      <div className="text-center" style={{ marginTop: '400px' }}>
        <div 
          className="spinner-border" 
          role="status"
          style={{
            color: 'rgb(105, 0, 225)',
            
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
  }

  if (!isAdmin) {
    return (
      <div>
        <Menu />
        <nav className="navbar navbar-light bg-body-tertiary custom-navbar">
          <div className="container-fluid">
            <NavLink className="navbar-brand gradient-text" to="#">
              Users
            </NavLink>
          </div>
        </nav>
        <UnauthorizedAccess />
      </div>
    );
  }

  return (
    <div>
      <Menu />
      <nav className="navbar navbar-light bg-body-tertiary custom-navbar">
        <div className="container-fluid">
          <NavLink className="navbar-brand gradient-text" to="#">
            Users
          </NavLink>
          <form className="d-flex input-group w-auto">
            <input type="search" className="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
          </form>

          
        </div>
        
      </nav>

      <div className="users-container">
        <div className="users-row">
          <div className="col-12 d-flex justify-content-end">
            <button className="btn-btn-add-user me-4" onClick={openModal}>
              Add User
            </button>
            <button className="btn-btn-delete-user" onClick={confirmDeleteUsers}>
              Delete
            </button>
          </div>
        </div>
      </div>
      {users.length > 0 ? (
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={users.length > 0 && selectedUsers.length === users.length}
                  onChange={e => handleSelectAll(e.target.checked)}
                />
              </th>
              <th>First Name</th>
              <th>Middle Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Company</th>
              <th>Designation</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(users) &&
              users.map((user, index) => (
                <tr key={index}>
                  <td>
                    <input type="checkbox" checked={selectedUsers.includes(user)} onChange={e => handleCheckboxChange(user, e.target.checked)} />
                  </td>
                  <td>{user.first_name}</td>
                  <td>{user.middle_name || " "}</td>
                  <td>{user.last_name || " "}</td>
                  <td>{user.email}</td>
                  <td>{user.phone || " "}</td>
                  <td>{user.company || " "}</td>
                  <td>{user.designation || " "}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
       ) : (
        <EmptyState />
      )}

      {showModal && (
        <div className="users-modal show" tabIndex={-1} role="dialog" style={{ display: "block" }}>
          <div className="users-modal-dialog users-modal-lg">
            <div className="users-modal-content">
              <div className="users-modal-header">
                <button type="button" className="users-close" aria-label="Close" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="users-modal-body">
                <form className="users-form-horizontal" onSubmit={handleAddUser}>
                  <div className="users-container">
                    <div className="row users-form-group">
                      <div className="col-sm-3">
                        <label className="users-control-label">Username:</label>
                      </div>
                      <div className="col-sm-6">
                        <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} required />
                      </div>
                    </div>
                    <div className="row users-form-group">
                      <div className="col-sm-3">
                        <label className="users-control-label">Email :</label>
                      </div>
                      <div className="col-sm-6">
                        <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} required />
                      </div>
                    </div>
                    <div className="row users-form-group">
                      <div className="col-sm-3">
                        <label className="users-control-label">Password:</label>
                      </div>
                      <div className="col-sm-6">
                        <input type="password" className="form-control" value={password} onChange={e => setPassword(e.target.value)} required />
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12 users-gap">
                    <div className="col-xs-offset-9 col-xs-3">
                      <div className="col-xs-12">
                      <button type="submit" className="btn-users-add" style={{ float: "right", marginLeft: 15 }} disabled={isAddingUser}>
                    {isAddingUser ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        
                      </>
                    ) : (
                      'ADD'
                    )}
                  </button>
                        <button type="button" className="btn-users-btn-cancel" style={{ float: "right" }} onClick={closeModal}>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {showPopup && <PopupMessage message={popupMessage} onClose={() => setShowPopup(false)} />}
      {showConfirmModal && <ConfirmationModal show={showConfirmModal} message="Are you sure you want to delete the selected users?" onConfirm={handleConfirmDelete} onCancel={handleCancelDelete} />}
    </div>
  );
}

export default Users;
