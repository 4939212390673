import React from 'react';

const UnauthorizedAccess = () => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(80vh - 50px)', // Adjust this value based on your navbar height
    textAlign: 'center',
    padding: '20px',
    boxSizing: 'border-box'
  }}>
    <svg className="empty-icon" viewBox="0 0 100 100" width="80" height="80" style={{ marginBottom: '20px' }}>
      <path d="M50 0C22.4 0 0 22.4 0 50s22.4 50 50 50 50-22.4 50-50S77.6 0 50 0zm0 90C27.9 90 10 72.1 10 50S27.9 10 50 10s40 17.9 40 40-17.9 40-40 40z" fill="rgb(146, 98, 201)"/>
      <path d="M50 25c-2.8 0-5 2.2-5 5v25c0 2.8 2.2 5 5 5s5-2.2 5-5V30c0-2.8-2.2-5-5-5z" fill="rgb(146, 98, 201)"/>
      <circle cx="50" cy="70" r="5" fill="rgb(146, 98, 201)"/>
    </svg>
    <p style={{
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#333',
      marginBottom: '10px'
    }}>Access Denied</p>
    <p style={{
      fontSize: '18px',
      color: '#666'
    }}>You don't have permission to view this page.</p>
  </div>
);

export default UnauthorizedAccess;