import React, { useState, useEffect } from "react";
import "./CloudProjects.css";
import { NavLink, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { Users, Plus } from "lucide-react";
import ViewProject from "./ViewProject";
import {
  getProjects,
  updateProject,
  deleteCloudNativeTask,
  createCloudNativeTask,
} from "./apiService";
import PopupMessage from "./PopupMessage"; // Import the PopupMessage component
import EmptyTasks from "./EmptyTasks";
import ParticipantsModal from "./ParticipantsModal";

function CloudProjects() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState({ name: "", description: "" });
  const [viewProject, setViewProject] = useState(null);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [showParticipants, setShowParticipants] = useState(false);
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setIsLoading(true);
        const data = await getProjects();
        console.log("Fetched projects:", data);
        setTasks(data || []);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setPopupMessage("Failed to fetch projects.");
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 5000);
        setTasks([]);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProjects();
  }, []);
  // Add this function to fetch the user count
  const fetchUserCount = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await fetch(
        "https://kakarotgenics-django-intermediate.azurewebsites.net/api/cloud-native-users/",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setUserCount(data.users?.length || 0);
    } catch (error) {
      console.error("Error fetching user count:", error);
      setUserCount(0);
    }
  };

  // Add useEffect to fetch user count when component mounts
  useEffect(() => {
    fetchUserCount();
  }, []);
  const toggleCreateModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Update the participants button click handler
  const handleParticipantsClick = (e) => {
    e.preventDefault();
    setShowParticipantsModal(true);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setNewTask({ ...newTask, [id]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const taskData = {
        name: newTask.name,
        description: newTask.description,
        selectedUsers: newTask.selectedUsers || [],
      };

      const createdTask = await createCloudNativeTask(taskData);

      const taskWithDetails = {
        id: createdTask.task_id,
        project_name: newTask.name,
        project_description: newTask.description,
        created_by: "Current User", // You might want to get this from the user's session
        created_time: new Date().toISOString(),
      };

      setTasks([...tasks, taskWithDetails]);

      setPopupMessage("Cloud Native Task Created Successfully");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);

      setNewTask({ name: "", description: "", selectedUsers: [] });
      toggleCreateModal();
    } catch (error) {
      console.error("Error creating task:", error);
      setPopupMessage("Failed to create task.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);
    }
  };

  const handleViewProject = (project) => {
    console.log("Project being viewed:", project);
    if (project && project.id) {
      // Changed from project.task_id to project.id
      navigate(`/task-details/${project.id}`);
    } else {
      console.error("Invalid project or id:", project);
      // Handle the error appropriately, maybe navigate to an error page
      navigate("/error");
    }
  };

  const closeViewProjectModal = () => {
    setViewProject(null);
  };

  // const handleUserSelect = (selectedOptions) => {
  //   setNewTask({ ...newTask, selectedUsers: selectedOptions.map(option => option.value) });
  // };

  const handleUpdateProject = async (updatedProject) => {
    try {
      const updatedData = await updateProject(viewProject.id, updatedProject);

      const updatedProjectWithDetails = {
        ...viewProject,
        ...updatedData,
        ...updatedProject,
      };

      const updatedProjects = tasks.map((project) =>
        project.id === viewProject.id ? updatedProjectWithDetails : project
      );
      const handleViewProject = (project) => {
        navigate("/project-details", { state: { project } });
      };

      setTasks([...updatedProjects]);

      setPopupMessage("Project Updated Successfully");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 1000);
      setViewProject(null);
    } catch (error) {
      console.error("Error updating project:", error);
      setPopupMessage("Failed to update project.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 1000);
    }
  };

  const handleDeleteClick = (e, project) => {
    e.stopPropagation(); // Prevent triggering the handleViewProject
    setProjectToDelete(project);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (projectToDelete) {
      try {
        await deleteCloudNativeTask(projectToDelete.id);
        setTasks(tasks.filter((task) => task.id !== projectToDelete.id));
        setPopupMessage("Cloud Native Task deleted successfully");
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
      } catch (error) {
        console.error("Error deleting task:", error);
        setPopupMessage("Failed to delete task");
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
      }
    }
    setDeleteModalOpen(false);
    setProjectToDelete(null);
  };

  return (
    <section className="cloud-projects-section">
      <Menu />
      <nav className="navbar navbar-light bg-body-tertiary custom-navbar">
        <div className="container-fluid">
          <div className="d-flex align-items-center gap-2">
            <NavLink className="navbar-brand gradient-text m-0" to="#">
              Tasks
            </NavLink>
            <div className="d-flex align-items-center gap-1">
              <button
                onClick={() => setShowParticipantsModal(true)}
                className="btn btn-link border-0 p-0 d-flex align-items-center position-relative"
                title="View and add participants"
              >
                <Users size={18} className="text-black" />
                <Plus
                  size={12}
                  className="text-black position-absolute"
                  style={{ top: "-4px", right: "-4px" }}
                />
              </button>
              <span className="text-black ms-1">{userCount}</span>
            </div>
          </div>

          <ParticipantsModal
            isOpen={showParticipantsModal}
            onClose={() => {
              setShowParticipantsModal(false);
              fetchUserCount(); // Refresh count when modal closes
            }}
          />
        </div>
      </nav>
      <button
        className="projects-btn-hover color-3"
        onClick={toggleCreateModal}
      >
        Create Task
      </button>
      {isLoading ? (
        <div className="text-center" style={{ marginTop: "300px" }}>
          <div
            className="spinner-border"
            role="status"
            style={{
              color: "rgb(105, 0, 225)",
              width: "2rem",
              height: "2rem",
            }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : tasks.length > 0 ? (
        <div className="cloud-projects">
          {tasks.map((project) => (
            <div key={project.id} className="card">
              <div className="card-content">
                <div
                  className="delete-icon"
                  onClick={(e) => handleDeleteClick(e, project)}
                >
                  <i className="bi bi-trash"></i>
                </div>
                <div onClick={() => handleViewProject(project)}>
                  <h2>{project.project_name}</h2>
                  <p>{project.project_description}</p>
                  <p>
                    <small>Created by: {project.created_by}</small>
                  </p>
                  <p>
                    <small>
                      Created on:{" "}
                      {new Date(project.created_time).toLocaleString()}
                    </small>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <EmptyTasks />
      )}

      <div
        className={`modal-projects modal fade ${isModalOpen ? "show" : ""}`}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!isModalOpen}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                New Task
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={toggleCreateModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="col-form-label">
                    Task name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={newTask.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="description" className="col-form-label">
                    Task Description:
                  </label>
                  <textarea
                    className="form-control"
                    id="description"
                    value={newTask.description}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
                {/* <div className="mb-3">
                  <label htmlFor="users" className="col-form-label">
                    Add Users (Optional):
                  </label>
                  <Select
  isMulti
  name="users"
  options={allUsers}
  className="basic-multi-select"
  classNamePrefix="select"
  onChange={handleUserSelect}
  placeholder="Select users..."
  noOptionsMessage={() => "No users found"}
/>
                </div> */}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn-cancel-projects"
                    onClick={toggleCreateModal}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn-create-project">
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {deleteModalOpen && (
        <div className="delete-modal">
          <div className="delete-modal-content">
            <h5>Delete Task</h5>
            <p>Are you sure you want to delete this task?</p>
            <p className="warning">This action cannot be undone.</p>
            <div className="button-container">
              <button
                className="cancel-btn"
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </button>
              <button className="delete-btn" onClick={handleDeleteConfirm}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      {viewProject && (
        <ViewProject
          project={viewProject}
          isOpen={Boolean(viewProject)}
          onClose={closeViewProjectModal}
          onUpdate={handleUpdateProject}
        />
      )}

      {showPopup && (
        <PopupMessage
          message={popupMessage}
          onClose={() => setShowPopup(false)}
        />
      )}
    </section>
  );
}

export default CloudProjects;
