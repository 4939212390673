import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { requestPasswordResetOTP, resetPassword } from "./apiService";
import "./ForgotPassword.css";
import PopupMessage from "./PopupMessage";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  // const [successful, setSuccessful] = useState(false)
  const [stage, setStage] = useState("requestOTP");
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const handleRequestOTP = async (e) => {
    e.preventDefault();
    try {
      await requestPasswordResetOTP(email);
      setPopupMessage("OTP email sent.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);
      setStage("verifyOTP");
    } catch (error) {
      setPopupMessage("Request Failed");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    // Here, you might call an API to verify the OTP.
    // Since there is no separate verification API, we'll assume success.
    if (otp) {
      setPopupMessage("OTP Verification successful.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);
      setStage("resetPassword");
    } else {
      setPopupMessage("OTP Verification failed.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await resetPassword(email, otp, newPassword1, newPassword2);
      setPopupMessage("Password reset successful");
      setShowPopup(true);
      setTimeout(() => {
        navigate("/login");
      }, 5000);
    } catch (error) {
      setPopupMessage("Failed to reset password");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);
    }
  };

  return (
    <div
      className="forgot-container d-flex justify-content-end align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="forgot-background"></div>

      <div className="forgot-col-md-5 forgot-card-container">
        <h2 className="forgot-text fw-bold">Forgot Password</h2>

        {stage === "requestOTP" && (
          <form onSubmit={handleRequestOTP}>
            <p className="su-start-hdng mt-3 mb-5">
              We’ll email you an OTP so you can reset your password.
            </p>
            <div className="forgot-form-group">
              <label htmlFor="email" className="forgot-lg-label">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-100 forgot-lg-inp"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="forgot-form-group forgot-text-center">
              <button className="btn-btn-forgot">Request OTP</button>
            </div>
          </form>
        )}
        {stage === "verifyOTP" && (
          <form onSubmit={handleVerifyOTP}>
            <div className="forgot-form-group">
              <label htmlFor="otp" className="forgot-lg-label">
                OTP
              </label>
              <input
                type="text"
                id="otp"
                className="w-100 forgot-lg-inp"
                name="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            </div>
            <div className="forgot-form-group forgot-text-center">
              <button className="btn-btn-forgot">Verify OTP</button>
            </div>
          </form>
        )}
        {stage === "resetPassword" && (
          <form onSubmit={handleResetPassword}>
            <div className="forgot-form-group">
              <label htmlFor="newPassword1" className="forgot-lg-label">
                Password
              </label>
              <input
                type="password"
                id="newPassword1"
                className="w-100 forgot-lg-inp"
                name="newPassword1"
                value={newPassword1}
                onChange={(e) => setNewPassword1(e.target.value)}
                required
              />
            </div>
            <div className="forgot-form-group">
              <label htmlFor="newPassword2" className="forgot-lg-label">
                Confirm Password
              </label>
              <input
                type="password"
                id="newPassword2"
                className="w-100 forgot-lg-inp"
                name="newPassword2"
                value={newPassword2}
                onChange={(e) => setNewPassword2(e.target.value)}
                required
              />
            </div>
            <div className="forgot-form-group forgot-text-center">
              <button className="btn-btn-forgot">Reset Password</button>
            </div>
          </form>
        )}

        <div className="forgot-form-group forgot-text-center">
          <p className="login-link">
            or{" "}
            <Link to="/login" className="purple-link">
              Log in
            </Link>
          </p>
        </div>
      </div>
      {showPopup && (
        <PopupMessage
          message={popupMessage}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
}

export default ForgotPassword;
