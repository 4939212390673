import React from "react";
import { NavLink } from "react-router-dom";
import {
  DashboardOutlined,
  TeamOutlined,
  UserOutlined,
  LogoutOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Menu.css";

export default function Menu() {
  return (
    <div className="sidebar">
      <ul className="navbar-nav">
        <li className="nav-item">
          <NavLink
            className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
            to="/profile"
            title="Profile"
          >
            <UserOutlined />
          </NavLink>
          <small className="menu-text">Profile</small>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
            to="/users"
            title="Users"
          >
            <TeamOutlined />
          </NavLink>
          <small className="menu-text">Users</small>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
            to="/modules"
            title="Projects"
          >
            <ProjectOutlined />
          </NavLink>
          <small className="menu-text">Projects</small>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
            to="/generic-modals"
            title="Generic"
          >
            <ProjectOutlined />
          </NavLink>
          <small className="menu-text">Generic</small>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
            to="/dashboard"
            title="Dashboard"
          >
            <DashboardOutlined />
          </NavLink>
          <small className="menu-text">Dashboard</small>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`}
            to="/login"
            title="Logout"
          >
            <LogoutOutlined />
          </NavLink>
          <small className="menu-text">Logout</small>
        </li>
      </ul>
    </div>
  );
}
