import React, { useState } from "react";
import "./GenericModals.css"; // We'll use the same CSS file
import Menu from "./Menu";
import { useNavigate, NavLink } from "react-router-dom";
import PopupMessage from "./PopupMessage";

function GenericModals() {
  const navigate = useNavigate();
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  // Static project data
  const projects = [
    {
      id: "sentiment-analysis",
      name: "Sentiment Analysis",
      description: "Analyze sentiment in text data",
      imageUrl:
        "https://cdn.prod.website-files.com/614c82ed388d53640613982e/64f7989c55786e5b4de9b9cb_sentiment-analysis-explained.webp",
    },
    {
      id: "1-level-thematic",
      name: "1 Level Thematic Analysis",
      description: "Thematic Analysis",
      imageUrl:
        "https://thumbs.dreamstime.com/b/wall-filled-various-data-charts-graphs-statistics-illustrating-complex-data-analysis-research-wall-covered-320487181.jpg",
    },

    {
      id: "2-level-thematic",
      name: "2 Level Thematic Analysis",
      description: "Thematic Analysis",
      imageUrl:
        "https://media.istockphoto.com/id/1660978161/photo/investing-stock-focusing-thematic-analysis-investing-2023.jpg?s=612x612&w=0&k=20&c=3AwKztajlctcCceKSg2M8Aqq4LO10txCOcOeMkaiDOQ=",
    },
    {
      id: "3-level-thematic",
      name: "3 Level Thematic Analysis",
      description: "Thematic Analysis",
      imageUrl:
        "https://cdn.prod.website-files.com/5e455d4bcdc92a0482407106/66900be8853af01f202ef7ea_data-visualization-1.webp",
    },
    {
      id: "PII-Gen-AI",
      name: "PII GenAi",
      description: "Pii GenAi",
      imageUrl: "https://www.dasera.com/hubfs/PII%201.webp",
    },
    {
      id: "gibberish-detector",
      name: "Gibberish Detector",
      description: "Gibberish Detector",
      imageUrl:
        "https://thumbs.dreamstime.com/b/code-javascript-language-white-background-developing-programming-binar-gibberish-dummy-lorem-ipsum-text-screen-web-dark-291324452.jpg",
    },
    {
      id: "text-translation",
      name: "Text Translator",
      description: "Translation",
      imageUrl:
        "https://images.ctfassets.net/a8pkciehvm1g/7bZju8whzq2FldF2tP7X6Q/9d90f58605fe86fabdfe3db7a6bf0030/_1200x675__4Q231101_-_Real-time_translation_guide_-_3_tools_and_4_best_practices_-_1.jpg?fl=progressive&fm=jpg&h=343&q=50&w=610",
    },
  ];

  const handleCreateTask = (projectId) => {
    // Navigate to the appropriate task creation page based on the project
    switch (projectId) {
      case "sentiment-analysis":
        navigate("/sentiment-analysis-tasks");
        break;
      case "1-level-thematic":
        navigate("/one-level-thematic-tasks");
        break;
      case "2-level-thematic":
        navigate("/two-level-thematic-tasks");
        break;
      case "3-level-thematic":
        navigate("/three-level-thematic-tasks");
        break;
      case "PII-Gen-AI":
        navigate("/pii-gen-ai-tasks");
        break;
      case "gibberish-detector":
        navigate("/gibberish-detector-tasks");
        break;
      case "text-translation":
        navigate("/text-translation-tasks");
        break;

      default:
        setPopupMessage("Invalid project selected");
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
    }
  };

  return (
    <section>
      <Menu />
      <nav className="navbar navbar-light bg-body-tertiary custom-navbar">
        <div className="container-fluid">
          <NavLink className="navbar-brand gradient-text" to="#">
            Generic Projects
          </NavLink>
        </div>
      </nav>

      <div className="Module-cards-container">
        {projects.map((project) => (
          <div className="home-card" key={project.id}>
            <div
              className="home-card-img"
              style={{ backgroundImage: `url(${project.imageUrl})` }}
            >
              <div className="home-overlay">
                <div className="home-overlay-content"></div>
              </div>
            </div>
            <div className="home-card-content">
              <h6>{project.name}</h6>
              <p>{project.description}</p>
              <button
                className="modules btn modules btn--block modules card__btn"
                onClick={() => handleCreateTask(project.id)}
              >
                Create Task
              </button>
            </div>
          </div>
        ))}
      </div>

      {showPopup && (
        <PopupMessage
          message={popupMessage}
          onClose={() => setShowPopup(false)}
        />
      )}
    </section>
  );
}

export default GenericModals;
