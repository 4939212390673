import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { HashRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil';



const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
     
     <HashRouter>
      <RecoilRoot>
        <App />
      </RecoilRoot>

    </HashRouter>
    
  </React.StrictMode>
  
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
