import React, { useRef, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import axios from "axios";
import Menu from "./Menu";
import PopupMessage from "./PopupMessage";
// import SentimentOutputab from './SentimentOutputab';
import "./ViewProject.css";
import "./ViewSentimentAnalysis.css";

const API_BASE_URL =
  "https://kakarotgenics-django-intermediate.azurewebsites.net/api";

function ViewPiiGenAiProjects() {
  const location = useLocation();
  const { taskId } = useParams();
  const [project, setProject] = useState(null);

  const [uploading, setUploading] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [selectedSheet, setSelectedSheet] = useState("");
  const [availableSheets, setAvailableSheets] = useState([]);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);
  const fileInputRef = useRef(null);
  const [activeTab, setActiveTab] = useState("input");
  const [isProcessing, setIsProcessing] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);
  const [isAnalysisStarted, setIsAnalysisStarted] = useState(false);
  const [wsError, setWsError] = useState(null);
  const socketRef = useRef(null);
  const [isFileReady, setIsFileReady] = useState(false);
  const [fileBlob, setFileBlob] = useState(null);

  const [visibleColumnStart, setVisibleColumnStart] = useState(0);
  const columnsToShow = 5;

  const totalColumns = uploadedData ? Object.keys(uploadedData[0]).length : 0;
  const totalPages = Math.ceil(totalColumns / columnsToShow);
  const currentPage = Math.floor(visibleColumnStart / columnsToShow) + 1;

  useEffect(() => {
    if (taskId) {
      console.log("Setting up Pii GenAI project with task ID:", taskId);
      fetchProjectData(taskId);
      //   fetchTaskStatus(taskId);
    }
  }, [taskId]);

  //   useEffect(() => {
  //     if (taskId && !isTaskCompleted) {
  //       setupWebSocket();
  //     }
  //   }, [taskId, isTaskCompleted]);

  //   const setupWebSocket = () => {
  //     const ws = new WebSocket(`wss://kakarotgenics-django-intermediate.azurewebsites.net/ws/sentiment-analysis/${taskId}/`);

  //     ws.onmessage = (event) => {
  //       const data = JSON.parse(event.data);
  //       handleWebSocketMessage(data);
  //     };

  //     ws.onerror = (error) => {
  //       console.error('WebSocket error:', error);
  //       handleWebSocketError(error);
  //     };

  //     socketRef.current = ws;
  //   };

  const fetchProjectData = async (id) => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        throw new Error("No access token found");
      }

      const response = await axios.post(
        `${API_BASE_URL}/get-pii-genai-task-detail/`,
        { task_id: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Project data response:", response.data);

      if (response.data && response.data.task_details) {
        const { task_details, file_blob } = response.data;
        setProject(task_details);
        setProjectDetails(task_details);
        setSelectedSheet(task_details.selected_sheet || "");
        setSelectedColumn(task_details.selected_column || "");

        if (file_blob) {
          const sentimentData = await processBase64Excel(file_blob);
          if (sentimentData) {
            setAvailableSheets(sentimentData.sheets);
            setUploadedData(sentimentData.data);
          }
        } else {
          console.log("No file blob available");
          setAvailableSheets([]);
          setUploadedData(null);
        }
      } else {
        console.error("Invalid response format:", response.data);
        setPopupMessage(
          "Failed to fetch project data. Invalid response format."
        );
        setShowPopup(true);
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
      setPopupMessage(
        "Failed to fetch project data. " +
          (error.response ? error.response.data.error : error.message)
      );
      setShowPopup(true);
    }
  };

  //   const fetchTaskStatus = async (id) => {
  //     try {
  //       const response = await axios.post(`${API_BASE_URL}/get-sentimental-analysis-task-status/`,
  //         { task_id: id },
  //         { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
  //       );
  //       setIsAnalysisStarted(response.data.is_analysis_started);
  //       setIsProcessing(response.data.is_analysis_started && !response.data.task_completed);
  //       setIsTaskCompleted(response.data.task_completed);
  //       setCompletionPercentage(response.data.task_completion_percentage || 0);
  //     } catch (error) {
  //       console.error("Error fetching task status:", error);
  //       setPopupMessage("Failed to fetch task status. Please try again.");
  //       setShowPopup(true);
  //     }
  //   };

  const handleWebSocketMessage = (data) => {
    console.log("Received WebSocket message:", data);
    if (data.task_completion_percentage !== undefined) {
      setCompletionPercentage(data.task_completion_percentage);
    }
    if (data.task_completed !== undefined) {
      setIsTaskCompleted(data.task_completed);
      setIsProcessing(false);
    }
  };

  const handleWebSocketError = (error) => {
    console.error("WebSocket error:", error);
    setWsError("Error connecting to server. Please try again.");
    setIsProcessing(false);
  };

  const processBase64Excel = async (base64String) => {
    try {
      const binaryString = atob(base64String);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const arrayBuffer = bytes.buffer;

      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const sheets = workbook.SheetNames;
      const firstSheet = sheets[0];
      const data = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);

      return { sheets, data };
    } catch (error) {
      console.error("Error processing base64 Excel data:", error);
      throw error;
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file || !taskId) {
      return;
    }

    setUploading(true);
    setUploadedFile(file);

    try {
      // Upload file to server
      const formData = new FormData();
      formData.append("task_id", taskId);
      formData.append("file", file);

      const response = await axios.post(
        `${API_BASE_URL}/upload-pii-genai-file/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (response.data.message) {
        setPopupMessage(response.data.message);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);

        // Read the file data
        const reader = new FileReader();
        reader.onload = async (event) => {
          const data = event.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetNames = workbook.SheetNames;

          setAvailableSheets(sheetNames);

          // If there's only one sheet, automatically select it
          if (sheetNames.length === 1) {
            const singleSheet = sheetNames[0];
            setSelectedSheet(singleSheet);

            // Automatically call the API to select the sheet
            try {
              const sheetResponse = await axios.post(
                `${API_BASE_URL}/select-pii-genai-sheet/`,
                {
                  task_id: taskId,
                  sheet_name: singleSheet,
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "accessToken"
                    )}`,
                  },
                }
              );

              // Load the worksheet data
              const worksheet = XLSX.utils.sheet_to_json(
                workbook.Sheets[singleSheet]
              );
              setUploadedData(worksheet);

              if (sheetResponse.data.message) {
                setPopupMessage("Sheet automatically selected");
                setShowPopup(true);
                setTimeout(() => setShowPopup(false), 2000);
              }
            } catch (error) {
              console.error("Error in auto-selecting sheet:", error);
              setPopupMessage(
                "Failed to auto-select sheet. Please select manually."
              );
              setShowPopup(true);
              setTimeout(() => setShowPopup(false), 2000);
            }
          } else {
            // If multiple sheets, set the first one as default but don't auto-select
            setSelectedSheet(sheetNames[0]);
            const worksheet = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetNames[0]]
            );
            setUploadedData(worksheet);
          }
        };

        reader.onerror = () => {
          setUploading(false);
          setPopupMessage("Failed to read the file.");
          setShowPopup(true);
          setTimeout(() => setShowPopup(false), 2000);
        };

        reader.readAsBinaryString(file);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploading(false);
      setPopupMessage("Failed to upload the file.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);
    } finally {
      setUploading(false);
    }
  };

  const handleSheetSelect = async (e) => {
    const selectedSheetName = e.target.value;
    setSelectedSheet(selectedSheetName);

    try {
      await axios.post(
        `${API_BASE_URL}/select-pii-genai-sheet/`,
        { task_id: taskId, sheet_name: selectedSheetName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      setPopupMessage("Sheet selected successfully.");
      setShowPopup(true);

      if (uploadedFile) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = event.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const worksheet = XLSX.utils.sheet_to_json(
            workbook.Sheets[selectedSheetName]
          );

          if (worksheet) {
            setUploadedData(worksheet);
          } else {
            console.error("Failed to parse worksheet.");
            setPopupMessage("Failed to load sheet data.");
            setShowPopup(true);
          }
        };
        reader.readAsBinaryString(uploadedFile);
      }
    } catch (error) {
      console.error("Error selecting sheet:", error);
      setPopupMessage("Failed to select sheet. Please try again.");
      setShowPopup(true);
    }
  };

  const handleColumnSelect = async (e) => {
    const columnName = e.target.value;
    setSelectedColumn(columnName);

    try {
      await axios.post(
        `${API_BASE_URL}/select-pii-genai-column/`,
        { task_id: taskId, column_name: columnName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      setPopupMessage("Column selected successfully.");
      setShowPopup(true);
    } catch (error) {
      console.error("Error selecting column:", error);
      setPopupMessage("Failed to select column. Please try again.");
      setShowPopup(true);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handlePageChange = (pageNumber) => {
    const newStartIndex = (pageNumber - 1) * columnsToShow;
    setVisibleColumnStart(newStartIndex);
  };

  const handleButtonClick = async () => {
    if (isFileReady) {
      handleDownloadFile();
    } else {
      await handleRunClick();
    }
  };

  const handleRunClick = async () => {
    if (!taskId) {
      setPopupMessage("Task ID is missing. Please try reloading the page.");
      setShowPopup(true);
      return;
    }

    setIsProcessing(true);
    setWsError(null);
    setIsFileReady(false);

    try {
      const response = await axios.post(
        `${API_BASE_URL}/run-pii-genai/`,
        { task_id: taskId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          responseType: "blob",
        }
      );

      if (response.data) {
        setFileBlob(response.data);
        setIsFileReady(true);
        setIsAnalysisStarted(true);
        setPopupMessage(
          "Pii Gen Ai Analysis completed. You can now download the file."
        );
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      console.error("Failed to run analysis:", error);
      let errorMessage = "Failed to run analysis. Please try again.";

      if (error.response) {
        errorMessage = "Server error. Please try again later.";
      } else if (error.request) {
        errorMessage =
          "No response received from server. Please check your connection.";
      } else {
        errorMessage = error.message;
      }

      setPopupMessage(errorMessage);
      setShowPopup(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDownloadFile = () => {
    if (fileBlob) {
      const url = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Pii_GenAi_result.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  };

  return (
    <div className="sentiment-analysis-projects">
      <Menu />
      <h2>{project?.task_name || "Pii GenAi Task"}</h2>
      <div className="mt-3">
        <p>{project?.task_description || "No description available."}</p>
      </div>
      <div className="progress-and-button-container">
        <div className="progress-container">
          <div
            className="progress-bar"
            style={{ width: `${completionPercentage}%` }}
          ></div>
        </div>
        <div className="run-button-container">
          <button
            className={`btn ${isFileReady ? "btn-success" : "btn-success"}`}
            onClick={handleButtonClick}
            disabled={(!selectedColumn && !isFileReady) || isProcessing}
          >
            {isProcessing
              ? "Processing..."
              : isFileReady
              ? "Download Result"
              : "Run"}
          </button>
        </div>
      </div>
      <div className="progress-info" style={{ marginBottom: "20px" }}>
        <div>Progress: {completionPercentage}%</div>
        {wsError && <div className="error-message">{wsError}</div>}
      </div>
      <div className="nav-tabs-container">
        <ul className="nav nav-tabs mb-3">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "input" ? "active" : ""}`}
              onClick={() => setActiveTab("input")}
            >
              Input Data
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "cost" ? "active" : ""}`}
              onClick={() => setActiveTab("cost")}
            >
              Cost Estimation
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "output" ? "active" : ""}`}
              onClick={() => setActiveTab("output")}
            >
              Output File
            </button>
          </li>
        </ul>
      </div>
      {activeTab === "input" && (
        <div className="view-project-container">
          <div className="left-section">
            <div className="inline-flex-container">
              <button
                className={`btn-excel-upload ${
                  isAnalysisStarted ? "analysis-started" : ""
                }`}
                onClick={handleUploadClick}
                disabled={uploading || isAnalysisStarted}
              >
                {uploading ? "Processing..." : "Upload File"}
              </button>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h6>Pii GenAI File</h6>
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </div>
            {availableSheets.length > 0 && (
              <div className="mt-3">
                <h6>Select a Sheet:</h6>
                <select
                  className="form-select"
                  value={selectedSheet}
                  onChange={handleSheetSelect}
                >
                  {availableSheets.map((sheetName, index) => (
                    <option key={index} value={sheetName}>
                      {sheetName}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {uploadedData && (
              <div className="mt-3">
                <h6>Select a Column:</h6>
                <select
                  className="form-select"
                  value={selectedColumn}
                  onChange={handleColumnSelect}
                >
                  <option value="" disabled>
                    Select a column
                  </option>
                  {Object.keys(uploadedData[0]).map((columnName, index) => (
                    <option key={index} value={columnName}>
                      {columnName}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {uploadedData && (
              <div className="mt-3">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        {Object.keys(uploadedData[0])
                          .slice(
                            visibleColumnStart,
                            visibleColumnStart + columnsToShow
                          )
                          .map((columnName, index) => (
                            <th key={index}>{columnName}</th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {uploadedData.slice(0, 3).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {Object.values(row)
                            .slice(
                              visibleColumnStart,
                              visibleColumnStart + columnsToShow
                            )
                            .map((cell, cellIndex) => (
                              <td key={cellIndex}>{cell}</td>
                            ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    Showing {columnsToShow} out of {totalColumns} columns
                  </div>
                  <div className="pagination">
                    <button
                      className={`btn btn-secondary ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages)].map((_, pageIndex) => (
                      <button
                        key={pageIndex}
                        className={`btn btn-secondary ${
                          currentPage === pageIndex + 1 ? "active" : ""
                        }`}
                        onClick={() => handlePageChange(pageIndex + 1)}
                      >
                        {pageIndex + 1}
                      </button>
                    ))}
                    <button
                      className={`btn btn-secondary ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {activeTab === "cost" && (
        <div className="cost-estimation-container">
          {/* Add your cost estimation content here */}
          <p>Cost estimation content goes here.</p>
        </div>
      )}
      {/* {activeTab === 'output' && (
        <SentimentOutputab 
          isTaskCompleted={isTaskCompleted}
          projectId={taskId}
          handleDownloadClick={handleDownloadClick}
        />
      )} */}
      {showPopup && <PopupMessage message={popupMessage} />}
    </div>
  );
}

export default ViewPiiGenAiProjects;
